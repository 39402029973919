<template>
  <main class="finalEvaluationPg">
    
    <div class="container">
      <div class="heading d-flex align-items-center justify-content-between">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          <b>Finalize Evaluation: </b> - Job ID
          <span class="orangeText">{{ jobId }}</span>
        </h4>
        <nest_notes_modal />
      </div>
      <div class="breadcrumbs border rounded p-2 mb-4">
        <ul class="d-flex list-inline mb-0">
          <li>
            <a :href="'/job/detail/' + jobId" class="greyText">Job Details</a>
          </li>
          <li>
            <a :href="'/select/unit/' + jobId" class="greyText">Units</a>
          </li>
          <li>
            <a :href="'/equipment/' + jobId + `?unit_id=` + this.$route.query.unit_id
            " class="greyText">Equipments</a>
          </li>
          <li>
            <a :href="'/summary/' + jobId + `?unit_id=` + this.$route.query.unit_id
            " class="greyText">Summary</a>
          </li>
          <li class="blueText">Finalize Evaluation: {{ jobId }}</li>
        </ul>
      </div>
      <div class="finalEvalSec" v-if="isApiCalled">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12 col-12" v-for="(item, index) in preChecksDetail" :key="index">

            <div v-if="item.identifier == 'is_diagnosis_fee_applicable'" class="finalEvaCard rounded py-3 px-4">
              <div class="finalEvaIcon secondarybg mx-auto d-table mb-3">
                <img src="../../assets/images/apply-eval-icon.png" alt="apply-eval-icon" />
              </div>
              <div class="finalEvaCardMain text-center">
                <h5 class="blueText fw-bold">{{ item.title }}</h5>
                <p>
                  {{ item.description }}
                </p>
                <div class="finalEvalFee mb-3 p-2 primaryBg rounded d-flex align-items-center justify-content-between">
                  <h6 class="mb-0 whiteText">Evaluation Fees</h6>
                  <h4 class="mb-0 whiteText">{{ item.amount_text }}</h4>
                </div>
                <p class="blackText">Apply evaluation fees</p>
                <div class="custom-switch">
                  <label for="my-checkbox" class="first-label label-displayed">No</label>
                  <input type="checkbox" name="" :true-value="1" :false-value="0"
                    v-model="storePreChecks[item.identifier]" id="my-switch" />
                  <label for="my-switch" class="label-hidden mx-2"></label>

                  <label for="my-checkbox" class="second-label label-displayed">Yes</label>
                </div>
              </div>
            </div>

            <div v-else-if="item.identifier == 'special_part_timestamp'" class="finalEvaCard rounded py-3 px-4">
              <div class="finalEvaIcon secondarybg mx-auto d-table mb-3">
                <img src="../../assets/images/spcial-parts-icon.png" alt="spcial-parts-icon" />
              </div>
              <div class="finalEvaCardMain text-center">
                <h5 class="blueText fw-bold">{{ item.title }}</h5>
                <p>
                  {{ item.description }}
                </p>
                <button @click="viewSpecialParts()" class="btn buttonOrangeWhite w-100">
                  View Parts
                </button>
                <div class="form-group mt-3 text-start">
                  <label class="mb-1 blackText">Select Date</label>
                  <date-picker input-class="disable-input" format="MM/DD/YYYY" :placeholder="'Select Date'" type="date"
                    :use12h="true" :confirm="true" :readonly="false" v-bind:clearable="false"
                    :default-value="new Date()" :disabled-date="disabledBefore" :disabled-time="disabledBeforeTime"
                    :editable="false" v-model:value="storePreChecks['partDate']" valueType="format"
                    @change="handleDateChange">
                  </date-picker>
                </div>
              </div>
            </div>

            <div v-else-if="item.identifier == 'is_create_child_booking'"
              class="finalEvaCard evalCard rounded py-3 px-4">
              <div class="finalEvaIcon secondarybg mx-auto d-table mb-3">
                <img src="../../assets/images/evaluate-big-icon.png" alt="evaluate-big-icon" />
              </div>
              <div class="finalEvaCardMain text-center">
                <h5 class="blueText fw-bold">
                  {{ item.title }}
                </h5>
                <p class="mb-5">
                  {{ item.description }}
                </p>
                <div class="custom-switch mb-5">
                  <label :for="item.identifier" class="first-label label-displayed">No</label><input type="checkbox" @click="onChildBookingClick(this)"
                    name="" :true-value="1" :false-value="0" v-model="storePreChecks[item.identifier]"
                    :id="item.identifier" /><label :for="item.identifier" class="label-hidden mx-2"></label><label
                    :for="item.identifier" class="second-label label-displayed">Yes</label>
                </div>
                <div class="form-group">
                  <div class="radioCheck orangeRadioCheck d-flex justify-content-between">
                    
                    <div class="form__radio-group" v-for="(opt, index) in item.services" :key="index">
                      <input :id="item.identifier+'-'+index" type="radio" class="form__radio-input" :name="item.identifier+'-radio'"
                      v-model="storePreChecks['child_booking_service_type']" :value="opt.id" />
                      <label :for="item.identifier+'-'+index" class="form__radio-label">
                        <span class="form__radio-button"></span>
                        <span class="form__radio-label-text">{{ opt.title }}</span>
                      </label>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="item.identifier == 'is_consume_agreement_frequency'"
              class="finalEvaCard maintenanceAgreement rounded py-3 px-4">
              <div class="finalEvaIcon secondarybg mx-auto d-table mb-3">
                <img src="../../assets/images/maintenance-agreement-icon.png" alt="maintenance-agreement-icon" />
              </div>
              <div class="finalEvaCardMain text-center">
                <h5 class="blueText fw-bold">{{ item.title }}</h5>
                <p>
                  {{ item.description }}
                </p>
                <div class="packageCard d-flex align-items-center justify-content-between p-2 rounded mb-2">
                  <p class="mb-0 blackText">Package</p>
                  <p class="mb-0 blueText fw-bold">{{ item.agreement.agreement_name }}</p>
                </div>
                <div class="packageCard d-flex align-items-center justify-content-between p-2 rounded mb-4">
                  <p class="mb-0 blackText">Remaining Frequencies</p>
                  <p class="mb-0 blueText fw-bold">{{ item.agreement.remaining_visits }}</p>
                </div>
                <p class="blackText">Apply Agreement</p>
                <div class="custom-switch">
                  <label :for="item.identifier" class="first-label label-displayed">No</label>

                  <input type="checkbox" name="" :true-value="1" :false-value="0"
                    v-model="storePreChecks[item.identifier]" :id="item.identifier" />
                  <label :for="item.identifier" class="label-hidden mx-2"></label>

                  <label :for="item.identifier" class="second-label label-displayed">Yes</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomButtons d-flex justify-content-center mt-5">
        <button @click="backButtonClicked()"
          class="btn buttonDark d-flex align-items-center me-2 text-uppercase rounded">
          back
        </button>
        <button @click="nextButtonClicked()" class="btn buttonPrimary d-flex align-items-center text-uppercase rounded">
          Next
        </button>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="viewSpecialPartsModal" ref="viewSpecialPartsModal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="modalCntnt">
                <h4 class="mb-0 blueText fw-bold text-center mb-4">
                  Special Parts
                </h4>
                <p class="mb-3 fw-bold text-center orangeText">
                  List of Special Parts included in your diagnosis.
                </p>
                <div class="specialPartsMainCard">
                  <div class="specialPartsModalCard border rounded mb-2 p-3" v-for="(item, index) in specialParts"
                    :key="index">
                    <h6 class="mb-2 blackText mt-1">{{ item.part_name }}</h6>
                    <ul class="list-inline mb-0 d-flex justify-content-between">
                      <li class="orangeText fw-bold">
                        Qty: {{ item.quantity }}
                      </li>
                      <li class="blueText fw-bold">
                        Price: ${{ item.cost_with_markup }}
                      </li>
                    </ul>
                  </div>
                </div>
                <button data-bs-dismiss="modal" aria-label="Close" class="modalBtn buttonPrimary mx-auto d-table mt-4">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import DatePicker from "vue-datepicker-next";
import { useToast } from "vue-toastification";
import nest_notes_modal from "../../components/nest_components/nest_notes_modal.vue";
const toast = useToast();
import { Modal } from "bootstrap";

export default {
  components: {
    DatePicker,
    nest_notes_modal,
  },

  computed: {
    ...mapGetters([
      "preChecksDetail",
      "preChecksStore",
      "specialParts",
      "storeChecksResponse",
      "getDiagnosisOptions",
    ]),
  },

  data() {
    return {
      jobId: this.$route.params.id,
      unit_id: this.$route.query.unit_id,
      modal: null,
      isApiCalled: false,
      storePreChecks: {
        is_diagnosis_fee_applicable: 0,
        child_booking_service_type: -1
      },
    };
  },

  mounted() {
    this.getPreChecksDetails({
      booking_id: this.jobId,
    });
  },

  watch: {
    specialParts: function () {
      this.modal = new Modal(this.$refs.viewSpecialPartsModal);
      this.modal.show();
    },

    preChecksDetail: function () {

    if(this.preChecksDetail.length <= 0){
      this.nextButtonClicked()
    }
    else{
      this.setValues();
      this.getDiagnosisOption({
        booking_id: this.jobId,
        diagnosis_type: "final_pre_checks",
      });
    }

    },

    getDiagnosisOptions: function () {
      this.setFinalPreChecks();
    },

    storeChecksResponse: function () {
      if (this.storePreChecks["partDate"] != null) {
        let data = moment(this.storePreChecks["partDate"]).unix();
        this.storePreChecks["special_part_timestamp"] = data;
      }
        if(this.preChecksDetail.length <= 0){
          this.storePreChecks["is_diagnosis_fee_applicable"] = 0
        }

      this.storePreChecks["booking_id"] = this.jobId;
      this.storePreChecksDetails(this.storePreChecks);
    },

    preChecksStore: function () {
      this.$router.push({
        path: `/summary-detail/${this.$route.params.id}`,
        query: {
          unit_id: this.unit_id,
          is_from_mobile: this.$route.query.is_from_mobile,
        },
      });
    },
  },

  methods: {
    ...mapActions([
      "getPreChecksDetails",
      "getSpecialParts",
      "storePreChecksDetails",
      "storeChecks",
      "getDiagnosisOption",
    ]),

    disabledBefore: function (date) {
      const cdate = new Date();
      cdate.setHours(0, 0, 0, 0);
      return date < cdate;
    },

    disabledBeforeTime: function (date) {
      const cdate = new Date();
      return date < cdate;
    },

    setValues() {
      let self = this;
      this.preChecksDetail.forEach(function (obj) {
        self.storePreChecks[obj.identifier] = obj.default_value;
      });
    },

    backButtonClicked() {
      this.$router.go(-1);
    },

    onChildBookingClick(cb){
      if (!cb.checked){
        this.storePreChecks.child_booking_service_type = -1
      }
    },

    setFinalPreChecks() {
      let diagnosis_data = JSON.parse(
        this.getDiagnosisOptions[0].diagnosis_data
      );

      for (let key in diagnosis_data) {
        this.storePreChecks[key] = diagnosis_data[key];
      }

      this.isApiCalled = true
    },

    nextButtonClicked() {
      if (this.isValid()) {
        this.storeChecks({
          booking_id: this.jobId,
          diagnosis_type: "final_pre_checks",
          diagnosis_data: JSON.stringify(this.storePreChecks),
        });
      }
    },

    isValid() {
      let status = true;
      if (
        this.storePreChecks["special_part_timestamp"] != undefined &&
        this.storePreChecks["special_part_timestamp"] == ""
      ) {
        status = false;
        toast.error("Special parts date and time required");
      } else if (
        this.storePreChecks["is_create_child_booking"] != undefined &&
        this.storePreChecks["is_create_child_booking"] == "1" && this.storePreChecks.child_booking_service_type == "-1"
      ) {
        status = false;
        toast.error("Please select another unit service type.");
      }

      return status;
    },

    viewSpecialParts() {
      this.getSpecialParts({
        booking_id: this.jobId,
      });
    },

    handleDateChange(newDate) {
      if (newDate) {
        let data = moment(newDate).unix();
        this.storePreChecks["special_part_timestamp"] = data;
      } else {
        this.unixTime = null;
      }
    },
  },
};
</script>
<style>
.finalEvaCard .buttonOrangeWhite {
  border-radius: 50px;
}

.specialPartsMainCard {
  height: 50vh;
  overflow-y: scroll;
}

.specialPartsMainCard::-webkit-scrollbar {
  width: 5px;
}

.specialPartsMainCard::-webkit-scrollbar-track {
  border-radius: 10px;
}

.specialPartsMainCard::-webkit-scrollbar-thumb {
  background: #fe5000;
  border-radius: 10px;
}

.maintenanceAgreement .packageCard {
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.75);
}
.finalEvaCard .form-group [type="radio"]:checked + label:before, .finalEvaCard .form-group [type="radio"]:not(:checked) + label:before {
    left: 4px;
    background: #092e87;
    top: -1px;
    border-color: #092e87;
}
.finalEvaCardMain p {
  font-size: 15px;
}

.finalEvaCardMain h5 {
  font-size: 18px;
}
</style>
