import FLoader from "@/components/f_loader/FLoader.vue";
import EventBus from "@/plugins/EventBus";
const Modal = {
    // every plugin for Vue.js needs install method
    // this method will run after Vue.use(<your-plugin-here>) is executed
    install(app) {

        // making our modal component global
        app.component('FLoader', FLoader)

        // exposing global $modal object with method show()
        // method show() takes object params as argument
        // inside this object we can have modal title, text, styles... and also our callback confirm function
        
        app.config.globalProperties.$loader = {
            show(params) {
                // if we use this.$modal.show(params) inside our original Vue instance
                // we will emit 'show' event with parameters 'params'
                EventBus.emit("loadershow", params);
            },

            hide(params) {
                // if we use this.$modal.show(params) inside our original Vue instance
                // we will emit 'show' event with parameters 'params'
                EventBus.emit("loaderhide", params);
            },
        }
    }
}

export default Modal