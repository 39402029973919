<template>
  <!-- equipmentSec start -->
  <div class="equipmentSec mt-5 mb-4">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="unitHead">
          <h3 class="unitInfo-heading">
            Enter or update the equipment information
          </h3>
          <div class="resetPart">
            <div @click="resetClicked()">
              <p class="resetBtn mb-0">
                Reset <img src="../../assets/images/reset-icon.png" alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="unitForm">
          <h3>System Type</h3>
          <input
            v-model="formData.equipment_type"
            v-on:keyup="validateField"
            type="text"
            placeholder=""
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="unitForm">
          <h3>Make</h3>
          <input
            v-model="formData.equipment_make"
            v-on:keyup="validateField"
            type="text"
            placeholder=""
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="unitForm">
          <h3>Model <span>*</span></h3>
          <input
            v-model="formData.equipment_model"
            v-on:keyup="validateField"
            type="text"
            placeholder=""
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="unitForm">
          <h3>Serial <span>*</span></h3>
          <input
            v-model="formData.equipment_serial"
            v-on:keyup="validateField"
            type="text"
            placeholder=""
            :disabled="serialDisable"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="unitForm">
          <h3>Date of Manufacture</h3>
          <date-picker
            input-class="disable-input"
            format="MM/DD/YYYY"
            :placeholder="'Select Date'"
            :confirm="true"
            :readonly="false"
            v-bind:clearable="false"
            @change="validateField"
            v-model:value="formData.equipment_manufacture_date"
            :disabled-date="disabledBefore"
            valueType="format"
            class="form-control"
          >
          </date-picker>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="text-center mt-3">
      <button
        :disabled="txtSerialDisable"
        @click="storeEquipmentInfoClicked()"
        class="btn main-button"
      >
        {{ this.$route.query.is_edit == 1 ? "Continue" : "Continue Editing" }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    DatePicker,
  },

  data() {
    return {
      txtSerialDisable: true,
      serialDisable: false,
      formData: {
        equipment_type: "",
        equipment_make: "",
        equipment_model: "",
        equipment_serial: "",
        equipment_manufacture_date: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "setEquipmentInfo",
      "saveEquipmentInfo",
      "quoteDetail",
      "saveEquipmentInfoStore",
    ]),
  },

  watch: {
    saveEquipmentInfo: function () {
      this.setData();
    },

    saveEquipmentInfoStore: function () {
      toast.success("Equipment information updated successfully.");
      this.setCommponent("QuoteItems");
    },
  },

  setUp() {},
  mounted() {
    this.getEquipmentInfoDetail();
  },

  methods: {
    ...mapActions(["getEquipmentInfo", "equipmentInfoStore", "setCommponent"]),

    resetClicked() {
      this.setData();
    },

    validateField() {
      // alert("validate" , this.formData.equipment_manufacture_date)

      if (this.isValid("watcher")) {
        this.txtSerialDisable = false;
      } else {
        this.txtSerialDisable = true;
      }
    },

    disabledBefore: function (date) {
      const cdate = new Date();
      cdate.setHours(0, 0, 0, 0);
      return date > cdate;
    },
    setData() {
      let equipmentInfo = {
        equipment_type:
          this.$store.state.product.saveEquipmentInfo.equipment_info
            .equipment_type ?? "",
        equipment_make:
          this.$store.state.product.saveEquipmentInfo.equipment_info
            .equipment_make ?? "",
        equipment_model:
          this.$store.state.product.saveEquipmentInfo.equipment_info
            .equipment_model ?? "",
        equipment_serial:
          this.$store.state.product.saveEquipmentInfo.equipment_info
            .equipment_serial ?? "",
        equipment_manufacture_date:
          this.$store.state.product.saveEquipmentInfo.equipment_info
            .equipment_manufacture_date ?? "",
      };
      this.serialDisable =
        equipmentInfo.equipment_serial.length <= 0 ? false : true;
      this.formData = equipmentInfo;
      this.validateField();
    },

    getEquipmentInfoDetail() {
      this.getEquipmentInfo({
        manual_quote_id: this.quoteDetail.id,
      });
    },

    storeEquipmentInfoClicked() {
      this.$dialog.show({
        title: "Equipment Information",
        text: "Are you sure you want to update equipment information?",
        // image: "pop-req-img.png",
        // we are passing callback method for our confirm button
        onConfirm: () => {
          if (this.isValid("submit")) {
            this.formData["manual_quote_id"] = this.quoteDetail.id;
            this.equipmentInfoStore(this.formData);
          }
        },
      });
    },

    isValid(action) {
      let status = true;
      let message = "";

      if (this.formData.equipment_type.length <= 0) {
        status = false;
        message = "System Type field is required.";
      } else if (this.formData.equipment_make.length <= 0) {
        status = false;
        message = "Make field is required.";
      } else if (this.formData.equipment_model.length <= 0) {
        status = false;
        message = "Model field is required.";
      }
      // else if (this.formData.equipment_serial.length <= 0) {
      //     status = false
      //     message = 'Serial field is requierd.'
      // }
      else if (this.formData.equipment_manufacture_date.length <= 0) {
        status = false;
      }

      if (!status && action == "submit") {
        toast.error(message);
      }

      return status;
    },
  },
};
</script>

<style>
.disable-input {
  pointer-events: none;
}
.unitHead {
  position: relative;
  margin-bottom: 3rem;
}
.unitForm {
  border-radius: 6px;
  margin-bottom: 20px;
}
.unitForm h3 {
  font-size: 17px;
  color: #092e87;
  font-weight: normal;
}
.resetPart {
  position: absolute;
  top: -4px;
  right: 0;
}
.resetPart img {
  width: 40px;
  margin-left: 0.5rem;
}
.unitForm h3 span {
  color: #fe5000;
}
.unitForm .form-control {
  height: 45px;
  border-color: #cacfd3;
}
.unitForm .form-control:focus {
  box-shadow: none;
}
.unitForm .mx-input-wrapper {
  position: relative;
  top: -5px;
}

.main-button {
  background: #fe5000;
  border-radius: 8px;
  box-shadow: 0 4px 5px #999;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
}

.main-button:active {
  background-color: #092e87;
  color: #fff;
}

.main-button:disabled {
  background-color: #ccc;
}

.unitInfo-heading {
  font-size: 26px;
  font-weight: 500;
  color: #092e87;
  text-align: center;
}

.unitForm .mx-datepicker {
  width: 100%;
}

.unitForm .mx-datepicker input {
  width: 100%;
  height: 40px;
  border-radius: 7px;
  border: none;
  padding: 0 0 0 12px;
}
.mx-icon-calendar{
  color: #fe5000;
}
.resetBtn {
  color: #fe5000;
  font-weight: 600;
  cursor: pointer;
}

.resetBtn:active {
  font-weight: 600;
  cursor: pointer;
  color: #092e87;
}
</style>
