<template>
    <div>
        <div class="modal fade modal-md show" id="modalDiscard" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog" style="display: block;">
            <div class="modal-dialog modal-dialog-centered">

                <div class="modal-content discard-wrap">
                    <div class="modal-header">
                        <!-- <button @click="closeModal" type="button" class="btnClose" data-bs-dismiss="modal"
                            aria-label="Close">
                            <img src="@/assets/images/cross-btn.png">
                        </button> -->
                    </div>
                    <div class="modal-body">
                        <div class="contPopup">
                            <h1 class="text-center">Cancel Quote</h1>
                            <div class="cardSec">
                                <h6 class="my-2">Cancellation Reason</h6>

                                <select v-model="reason" class="form-select form-select-sm"
                                    aria-label=".form-select-sm example">
                                    <option value="" selected disabled>Select Option</option>
                                    <option v-for="(item, idx) in cancelReasons" :key="idx">
                                        {{ item }}
                                    </option>
                                </select>
                                <div v-if="reason == 'Other'">
                                    <h6 class="my-2">Please mention reason</h6>
                                    <textarea v-model="reasonText" class="form-control myTextHere"
                                        placeholder="Enter Text here..."></textarea>
                                </div>
                            </div>
                            <div v-if="quoteDetail && quoteDetail.partner_info && quoteDetail.partner_info.is_tradecall_fee_applicable == 1"
                                class="cardSec mt-4 ">
                                <h6>A Trade call free for {{ quoteDetail.partner_info.tradecall_fee_text }} is
                                    applicable on this claim. Have you collected the Trade call free from {{
                            quoteDetail.consumer.full_name }} ?</h6>
                                <div class="d-flex radioBtn">
                                    <label><input v-model="check" type="radio" value="1"> Yes</label>
                                    <label class="ms-auto"><input v-model="check" type="radio" value="0"> No</label>
                                </div>
                            </div>

                            <div class="text-center d-flex justify-content-evenly mt-4">
                                <span class="btn main-button mb-4 disBtn" @click="closeModal">Dismiss</span>
                                <span class="btn main-button mb-4 " @click="confirm">Cancel Quote</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
} from "vuex";
import { useToast } from 'vue-toastification';
const toast = useToast();


export default {
    components: {

    },

    data() {
        return {
            check: '',
            reason: '',
            reasonText: ''
        }
    },
    computed: {
        ...mapGetters(["quoteDetail", "apptDetail", "cancelReasons"])
    },

    watch: {

    },

    mounted() {

    },

    methods: {
        ...mapActions(["setCommponent"]),

        editQuoteClicked() {
            this.setCommponent("QuoteItems")
        },

        closeModal() {
            this.check = ''
            this.reason = ''
            this.$emit("event", "close")
        },

        confirm() {
            if (this.reason.length <= 0) {
                toast.error("Reason field is required.");
            } else if (this.reason == "Other" && this.reasonText.length <= 0) {
                toast.error("Reason field is required.");
            } else if (this.quoteDetail.partner_info && this.quoteDetail.partner_info.is_tradecall_fee_applicable == 1 && this.check.length <= 0) {
                toast.error("Please confirm have you collected the Trade Call fee.");
            } else {
                let ch = this.check.length <= 0 ? "0" : this.check
                let res = this.reason == "Other" ? this.reasonText : this.reason
                this.$emit("event", "confirm", { is_tradecall_fee_collected: ch, reason: res })
                this.check = ''
                this.reason = ''
            }

        },
    }

}
</script>

<style>
.modals-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modals {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.discard-wrap {
    border-radius: 20px;
    box-shadow: 0 0 10px #fff;
}

.discard-wrap .modal-header {
    border: none;
}

.continue-popup .modal-header .close {
    position: absolute;
    top: 30px;
    right: 30px;
    background: #ff5b01;
    opacity: 1;
    padding: 0;
    height: 27px;
    width: 27px;
    border-radius: 50px;
    color: #fff;
    box-shadow: 0 2px 3px #444;
    font-weight: 100;
}

.continue-popup .modal-header {
    padding: 0;
    border: none;
}

.continue-popup {
    border-radius: 12px;
    box-shadow: 0 0 10px #fff;
    border: none;
}

/* .contPopup h1 {
    color: #003087;
    font-size: 25px;
    font-weight: 600;
    text-shadow: 0 1px 3px #666;
    margin-top: -30px;
}

.contPopup p {
    color: #000000;
    font-size: 15px;
} */

.btnClose {
    position: absolute;
    background: none;
    right: 10px;
    padding: 0;
    border: none;
    border-radius: 50px;
    z-index: 9;
    font-weight: bold;
}

.myCheckText {
    color: #ff5b01;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.myCheck {
    height: 15px;
    width: 15px;
    accent-color: #ff5b01;
}

.disBtn {
    background-color: #003087 !important;
}

.myTextHere {
    height: 80px;
    resize: none;
    font-size: .875rem;
}

.radioBtn {
    max-width: 200px;
    accent-color: #ff5b01;
}

.cardSec {
    background-color: #f8f8f8;
    border-radius: 10px !important;
    padding: 10px;


}

.cardSec p {
    font-size: 16px !important;
    color: black !important;
}


@media screen and (max-width:767px) {}
</style>