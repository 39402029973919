<template>
  <!-- uploadButton start -->
  <div class="uploadSec">
    <div class="uploadButton">
      <div class="text-center">
        <button
          :disabled="images.length >= 8"
          class="btn main-button mb-4"
          @click="image"
        >
          Upload images
        </button>
        <p>(Maximum 8 images allowed)</p>
      </div>
    </div>
    <div
      :class="images.length > 0 ? 'gallery-row mt-5 mb-5' : 'gallery-row mt-5'"
      v-viewer="{ rotatable: false, scalable: false }"
    >
      <div v-for="(item, index) in images" :key="index" class="gallery-col">
        <div class="imgBox-wrap">
          <div class="imgBox">
            <img :src="item.url" class="img-fluid" />
          </div>
          <div v-if="!item.id">
            <div class="donut"></div>
          </div>
          <span v-else>
            <div
              @click="deleteImage(item)"
              class="remove-image staticImg"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/delete-icon.png') + ')',
              }"
            ></div>
            <div
              @click="deleteImage(item)"
              class="remove-image hoverImg"
              :style="{
                backgroundImage:
                  'url(' +
                  require('@/assets/images/delete-fill-icon.png') +
                  ')',
              }"
            ></div>
          </span>
        </div>
      </div>
    </div>
    <input
      ref="fileInput"
      @change="updateImageDisplay"
      type="file"
      accept="image/*"
      id="actual-btn"
      hidden
    />
    <VueBottomSheet ref="bottomSheet">
      <div class="bottomsheet">
        <!-- <input @change="updateImageDisplay" type="file" accept=".jpg, .jpeg, .png" id="actual-btn" hidden> -->
        <h3 class="text-center" @click="optionClicked(1)">
          <!-- <input v-if="type='camera'" @change="updateImageDisplay" type="file" accept=".jpg, .jpeg, .png" id="actual-btn" hidden capture> -->

          <label for="actual-btn"> Open Camera </label>
        </h3>
        <hr />
        <h3 class="text-center" @click="optionClicked(2)">
          <!-- <input  v-if="type='gallery'"  @change="updateImageDisplay" type="file" accept=".jpg, .jpeg, .png" id="actual-btn" hidden capture="filesystem"> -->

          <label for="actual-btn"> Open Gallery </label>
        </h3>

        <br />
      </div>
    </VueBottomSheet>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();

import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["isBefore"],

  components: {
    VueBottomSheet,
  },

  data() {
    return {
      images: [],
      type: "",
    };
  },
  computed: {
    ...mapGetters([
      "quoteDetail",
      "quoteImageUploaded",
      "quoteImages",
      "deleteQuoteImage",
    ]),
  },

  watch: {
    deleteQuoteImage: function () {
      if (this.deleteQuoteImage) {
        this.updateDeletedQuoteImages();
        this.getImages();
      }
    },

    quoteImageUploaded: function () {
      this.images[0].isLoading = false;

      this.images.shift();
      this.images.splice(0, 0, this.quoteImageUploaded);
    },

    quoteImages: function () {
      this.images = this.quoteImages;
    },
  },

  mounted() {
    this.getImages();
  },

  methods: {
    ...mapActions([
      "uploadQuoteImage",
      "getQuoteImages",
      "deleteQuoteImages",
      "updateDeletedQuoteImages",
    ]),

    deleteImage(item) {
      this.$dialog.show({
        title: "Delete Image",
        text: "Are you sure you want to delete this image?",
        // we are passing callback method for our confirm button
        onConfirm: () => {
          this.deleteQuoteImages({
            manual_quote_id: this.$route.params.id,
            id: item.id,
          });
        },
      });
    },

    getImages() {
      let params = {
        manual_quote_id: this.$route.params.id,
        is_before: this.isBefore,
      };
      this.getQuoteImages(params);
    },

    uploadImage(e) {
      const file = e.target.files[0];
      let formData = new FormData();
      formData.append("document", file);
      formData.append("manual_quote_id", this.$route.params.id);
      formData.append("is_before", this.isBefore);
      formData.append("index", 0);
      formData.append("timestamp", new Date().getTime());

      this.uploadQuoteImage(formData);
    },
    updateImageDisplay(e) {
      const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB

      if (fileSize <= 10) {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        var obj = {
          isLoading: true,
          url: url,
        };

        this.images.splice(0, 0, obj);
        this.uploadImage(e);
      } else {
        toast.error("File size should not be greater than 10MB.");
      }
    },
    image() {
      // this.$refs.bottomSheet.open();
      this.$refs.fileInput.click();
    },

    optionClicked(data) {
      this.$refs.bottomSheet.close();
      if (data == 1) {
        this.type = "camera";
      } else if (data == 2) {
        this.type = "gallery";
      }
    },
  },
};
</script>

<style>
.uploadSec {
  /* padding-bottom: 3rem; */
  border-bottom: 1px solid #cacfd3;
}
.uploadButton p {
  background: #fff0e9;
  width: max-content;
  margin: 0 auto;
  display: table;
  padding: 0.8rem 1.5rem;
  color: #fd5001;
  border-radius: 15px;
}
.imgBox-wrap {
  text-align: center;
  position: relative;
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  padding: 0.5rem;
  margin: 0;
  border-radius: 10px;
  margin-bottom: 0 !important;
}
.remove-image {
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  margin: 0 auto;
}
.imgBox-wrap:hover .hoverImg {
  display: block;
}
.imgBox-wrap:hover .staticImg {
  display: none;
}
.hoverImg {
  display: none;
}
.gallery-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10px;
}

.gallery-col {
}

.imgBox {
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  height: 120px;
  width: 134px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #000;
  justify-content: center;
}

.imgBox img {
  width: auto;
  max-height: 100%;
  border-radius: 0;
  transition: all ease 0.3s;
}

.imgBox-wrap {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  cursor: zoom-in;
}

.imgBox-wrap:hover img {
  border-radius: 0;
  transition: all ease 0.3s;
  transform: scale(1.1);
}

.imgBox-wrap:hover .imgBox {
  border: 2px solid #fe5000;
}

/* .imgBox-wrap span {
  position: absolute;
  top: 0px;
  right: 5px;
} */

/* .imgBox-wrap span img {
  height: 17px;
} */

.imgBox-wrap:hover span {
  color: #092e87;
}

.main-button {
  background: #fe5000;
  border-radius: 8px;
  box-shadow: 0 4px 5px #999;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
}

.main-button:hover {
  background-color: #092e87;
  color: #fff;
}

.bottomsheet label {
  cursor: pointer;
}

.bottomsheet label:active {
  color: #092e87;
}

/* Donut spinner */
@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.donut {
  display: inline-block;
  border: 3px solid #ff7d55;
  border-left-color: #092e87;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: donut-spin 1s linear infinite;
  position: absolute;
  top: 5px;
  right: 5px;
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .consStep .consTab {
    padding: 1rem;
  }
  .consStep .stepLine {
    left: -37px !important;
    width: 50px !important;
  }
  .consStep .consTab p {
    font-size: 15px !important;
  }
  .imgBox-wrap {
    margin-bottom: 1rem !important;
  }
  .gallery-row {
    max-width: 80%;
    margin: 0 auto;
  }
  .imgBox {
    height: 120px !important;
    width: 120px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .gallery-row {
    max-width: 100%;
    margin: 0 auto;
  }
  .imgBox-wrap {
    margin-bottom: 1rem !important;
  }
  .imgBox {
    height: 100px !important;
    width: 100px !important;
  }
  
}

/* @media screen and (max-width: 992px) {
  .imgBox-wrap {
    margin-bottom: 1rem !important;
  }
  .imgBox {
    height: 80px !important;
    width: 90px !important;
  }
} */
@media screen and (max-width: 567px) {
  .imgBox-wrap {
    margin-bottom: 1rem !important;
  }
  .imgBox {
    height: 60px !important;
    width: 75px !important;
  }
}
</style>
