<template>
  
  <div v-if="quoteSummary != null" class="modal-content quoteModal-wrap">
   
    <div class="modal-header d-flex justify-content-between align-items-center">
      <div class="modal-header-left d-flex align-items-center">
        <img
          v-if="!readOnly"
          @click="editQuoteClicked"
          data-bs-dismiss="modal"
          src="@/assets/images/edit-icon.png"
          class="edit-icon me-2"
        />
        <h5 class="modal-title" id="exampleModalLabel">Quote Summary</h5>
      </div>
      <button
        type="button"
        class="btn-close crossBtn-pop"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <img src="@/assets/images/cross-btn.png" />
      </button>
    </div>
    <div class="modal-body">
      <div class="quoteItems-heading">
        <h3>Items Added</h3>
        <h2>
          <small>$</small>{{ quoteSummary.item_total_text.replace("$", "") }}
        </h2>
      </div>

      <div v-if="quoteSummary.items.length > 0" class="modalPricing-box">
        <ul v-for="(item, index) in quoteSummary.items[0].data" :key="index">
          <li>
            <span
              >{{ item.option }} :
              <p v-if="item.sub_text.length > 0">({{ item.sub_text }})</p>
            </span>
            <span
              ><small>$</small>{{ item.total_cost_text.replace("$", "") }}</span
            >
          </li>
        </ul>
      </div>
      <div v-if="quoteSummary.items.length > 1">
        <div
          v-for="(item, index) in quoteSummary.items[1].data"
          :key="index"
          class="quoteItems-heading mt-2"
        >
        
          <h3>
            <span v-if="quoteDetail.partner_info.company_name.toLowerCase() == 'vivint'">
              Vivint Coverage
            </span>
            <span v-else>
                {{quoteSummary.items[1].heading }}
            </span>

            <p>
              {{ item.claim_heading }}
              <span class="displayBlockM"> {{ item.claim_text }} </span>
            </p>
          </h3>
          <h2><small>$</small>{{ item.total_cost_text.replace("$", "") }}</h2>
        </div>
      </div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer">
      <h3>Quote Total:</h3>
      <h2><small>$</small>{{ quoteSummary.total_text.replace("$", "") }}</h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["quoteSummary", "readOnly"],
  components: {},

  data() {
    return {};
  },
  computed: {
    ...mapGetters(["quoteDetail"]),

  },

  watch: {},

  mounted() {},

  methods: {
    ...mapActions(["setCommponent"]),

    editQuoteClicked() {
      this.setCommponent("QuoteItems");
    },
  },
};
</script>

<style>
.quoteItems-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quoteItems-heading h3 {
  font-weight: 700;
  color: #092e87;
  font-size: 25px;
  margin: 0;
}

.quoteItems-heading h3 p {
  font-weight: normal;
  font-size: 18px;
  color: #3c3c3c;
}

.quoteItems-heading h2 {
  font-weight: 400;
  color: #fe5000;
  font-size: 35px;
  margin: 0;
}

.quoteItems-heading h2 small {
  font-size: 20px;
  position: relative;
  top: -10px;
}

.modalPricing-box {
  background: #d9d9d9;
  padding: 15px;
  border-radius: 10px;
  max-height: 330px;
  overflow-y: scroll;
}

/* width */
.modalPricing-box::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
.modalPricing-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.modalPricing-box ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.modalPricing-box ul li {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 20px;
  color: #092e87;
  border-bottom: 1px solid #3c3c3ca2;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.modalPricing-box ul li p {
  color: #3c3c3c;
  margin: 0;
}

.modalPricing-box ul li small {
  font-size: 14px;
  position: relative;
  top: -3px;
}

.quoteModal-wrap .modal-header {
  background: #092e87;
  color: #fff;
  padding: 10px 20px;
}

.quoteModal-wrap .modal-header .btn-close {
  opacity: 1;
  text-indent: 000;
  background: none;
  margin: 0;
  height: auto;
  padding: 0;
  box-shadow: none;
}

.quoteModal-wrap .modal-header .edit-icon {
  cursor: pointer;
  height: 22px;
  width: 22px;
}

.crossBtn-pop img {
  height: 22px;
  width: 22px;
}

.quoteModal-wrap .modal-footer {
  background: #092e87;
  color: #fff;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}

.quoteModal-wrap .modal-footer h3 {
  font-size: 20px;
}

.quoteModal-wrap .modal-footer h2 {
  font-size: 35px;
}

.quoteModal-wrap .modal-footer h2 small {
  font-size: 20px;
  position: relative;
  top: -10px;
}

@media screen and (max-width: 990px) {
  .displayBlockM {
    display: block;
  }
}
</style>
