<template>
  <main class="indoorEquipPg">
    <div class="container">
      <div class="heading d-flex align-items-center justify-content-between">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          <b>Indoor Equip. </b> - Job ID <span class="orangeText">8448</span>
        </h4>
        <a href="#" class="text-decoration-none">
          <span class="me-2 blackText">Notes</span>
          <img src="../../assets/images/notes-icon.png" alt="notes-icon" />
        </a>
      </div>
      <div class="breadcrumbs border rounded p-2 mb-4">
        <ul class="d-flex list-inline mb-0">
          <li><a  class="greyText">My Account</a></li>
          <li>
            <a  class="greyText"
              >Service & Repair History</a
            >
          </li>
          <li>
            <a :href="'/job/detail/'+jobId" class="greyText">Job Detail</a>
          </li>
          <li class="blueText">Indoor Equip. - Job ID 8448</li>
        </ul>
      </div>
      <div class="indoorEquipSec">
        <div
          class="indoorEquipHead d-flex align-items-center justify-content-between my-4"
        >
          <h5 class="blueText mb-0">Split System</h5>
          <ul class="list-inline mb-0 d-flex">
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/green-icon.png"
                class="me-2"
                alt="green-icon"
              />
              <p class="mb-0">Operating as per manufacturer's specification</p>
            </li>
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/duck-head-icon.png"
                class="me-2"
                alt="yellow-icon"
              />
              <p class="mb-0">
                Not operating as per manufacturer's specification
              </p>
            </li>
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/electric-head-icon.png"
                class="me-2"
                alt="red-icon"
              />
              <p class="mb-0">Non Operation / Critical</p>
            </li>
          </ul>
        </div>
        <div class="indoorUnit">
          <h5 class="primaryBg whiteText p-2 rounded">Indoor Unit</h5>
          <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Air Cooler</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="airCollerYes"
                      type="radio"
                      class="form__radio-input"
                      name="airColler"
                      checked
                    />
                    <label
                      for="airCollerYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="airCollerPending"
                      type="radio"
                      class="form__radio-input"
                      name="airColler"
                    />
                    <label
                      for="airCollerPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="airCollerNo"
                      type="radio"
                      class="form__radio-input"
                      name="airColler"
                    />
                    <label for="airCollerNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">UV Light</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="uvLightYes"
                      type="radio"
                      class="form__radio-input"
                      name="uvLight"
                      checked
                    />
                    <label
                      for="uvLightYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="uvLightPending"
                      type="radio"
                      class="form__radio-input"
                      name="uvLight"
                    />
                    <label
                      for="uvLightPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="uvLightNo"
                      type="radio"
                      class="form__radio-input"
                      name="uvLight"
                    />
                    <label for="uvLightNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Burner</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="burnerYes"
                      type="radio"
                      class="form__radio-input"
                      name="burner"
                      checked
                    />
                    <label for="burnerYes" class="form__radio-label greenRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="burnerPending"
                      type="radio"
                      class="form__radio-input"
                      name="burner"
                    />
                    <label
                      for="burnerPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="burnerNo"
                      type="radio"
                      class="form__radio-input"
                      name="burner"
                    />
                    <label for="burnerNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Condensate Pump</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="condensatePumpYes"
                      type="radio"
                      class="form__radio-input"
                      name="condensatePump"
                      checked
                    />
                    <label
                      for="condensatePumpYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="condensatePumpPending"
                      type="radio"
                      class="form__radio-input"
                      name="condensatePump"
                    />
                    <label
                      for="condensatePumpPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="condensatePumpNo"
                      type="radio"
                      class="form__radio-input"
                      name="condensatePump"
                    />
                    <label
                      for="condensatePumpNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Control Board</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="cbYes"
                      type="radio"
                      class="form__radio-input"
                      name="cb"
                      checked
                    />
                    <label for="cbYes" class="form__radio-label greenRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="cbPending"
                      type="radio"
                      class="form__radio-input"
                      name="cb"
                    />
                    <label
                      for="cbPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="cbNo"
                      type="radio"
                      class="form__radio-input"
                      name="cb"
                    />
                    <label for="cbNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Disconnect</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="discnnctYes"
                      type="radio"
                      class="form__radio-input"
                      name="discnnct"
                      checked
                    />
                    <label
                      for="discnnctYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="discnnctPending"
                      type="radio"
                      class="form__radio-input"
                      name="discnnct"
                    />
                    <label
                      for="discnnctPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="discnnctNo"
                      type="radio"
                      class="form__radio-input"
                      name="discnnct"
                    />
                    <label for="discnnctNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Drain Pan/Line</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="drainPanYes"
                      type="radio"
                      class="form__radio-input"
                      name="drainPan"
                      checked
                    />
                    <label
                      for="drainPanYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="drainPanPending"
                      type="radio"
                      class="form__radio-input"
                      name="drainPan"
                    />
                    <label
                      for="drainPanPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="drainPanNo"
                      type="radio"
                      class="form__radio-input"
                      name="drainPan"
                    />
                    <label for="drainPanNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Duct Work</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="ductWorkYes"
                      type="radio"
                      class="form__radio-input"
                      name="ductWork"
                      checked
                    />
                    <label
                      for="ductWorkYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="ductWorkPending"
                      type="radio"
                      class="form__radio-input"
                      name="ductWork"
                    />
                    <label
                      for="ductWorkPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="ductWorkNo"
                      type="radio"
                      class="form__radio-input"
                      name="ductWork"
                    />
                    <label for="ductWorkNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Electrical Connections</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="eConYes"
                      type="radio"
                      class="form__radio-input"
                      name="eCon"
                      checked
                    />
                    <label for="eConYes" class="form__radio-label greenRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="eConPending"
                      type="radio"
                      class="form__radio-input"
                      name="eCon"
                    />
                    <label
                      for="eConPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="eConNo"
                      type="radio"
                      class="form__radio-input"
                      name="eCon"
                    />
                    <label for="eConNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Evaporator Coil</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="evaporatprCoilYes"
                      type="radio"
                      class="form__radio-input"
                      name="evaporatprCoil"
                      checked
                    />
                    <label
                      for="evaporatprCoilYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="evaporatprCoilPending"
                      type="radio"
                      class="form__radio-input"
                      name="evaporatprCoil"
                    />
                    <label
                      for="evaporatprCoilPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="evaporatprCoilNo"
                      type="radio"
                      class="form__radio-input"
                      name="evaporatprCoil"
                    />
                    <label
                      for="evaporatprCoilNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Filter</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="filterYes"
                      type="radio"
                      class="form__radio-input"
                      name="filter"
                      checked
                    />
                    <label for="filterYes" class="form__radio-label greenRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="filterPending"
                      type="radio"
                      class="form__radio-input"
                      name="filter"
                    />
                    <label
                      for="filterPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="filterNo"
                      type="radio"
                      class="form__radio-input"
                      name="filter"
                    />
                    <label for="filterNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Fan Belt</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="fanbeltYes"
                      type="radio"
                      class="form__radio-input"
                      name="fanbelt"
                      checked
                    />
                    <label
                      for="fanbeltYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="fanbeltPending"
                      type="radio"
                      class="form__radio-input"
                      name="fanbelt"
                    />
                    <label
                      for="fanbeltPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="fanbeltNo"
                      type="radio"
                      class="form__radio-input"
                      name="fanbelt"
                    />
                    <label for="fanbeltNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Flame Color</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="flameColorYes"
                      type="radio"
                      class="form__radio-input"
                      name="flameColor"
                      checked
                    />
                    <label
                      for="flameColorYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="flameColorPending"
                      type="radio"
                      class="form__radio-input"
                      name="flameColor"
                    />
                    <label
                      for="flameColorPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="flameColorNo"
                      type="radio"
                      class="form__radio-input"
                      name="flameColor"
                    />
                    <label
                      for="flameColorNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Flame Rod</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="flameRodYes"
                      type="radio"
                      class="form__radio-input"
                      name="flameRod"
                      checked
                    />
                    <label
                      for="flameRodYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="flameRodPending"
                      type="radio"
                      class="form__radio-input"
                      name="flameRod"
                    />
                    <label
                      for="flameRodPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="flameRodNo"
                      type="radio"
                      class="form__radio-input"
                      name="flameRod"
                    />
                    <label for="flameRodNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Float Switch</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="floatSwitchYes"
                      type="radio"
                      class="form__radio-input"
                      name="floatSwitch"
                      checked
                    />
                    <label
                      for="floatSwitchYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="floatSwitchPending"
                      type="radio"
                      class="form__radio-input"
                      name="floatSwitch"
                    />
                    <label
                      for="floatSwitchPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="floatSwitchNo"
                      type="radio"
                      class="form__radio-input"
                      name="floatSwitch"
                    />
                    <label
                      for="floatSwitchNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Flue Pipe</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="fluePipeCapYes"
                      type="radio"
                      class="form__radio-input"
                      name="fluePipeCap"
                      checked
                    />
                    <label
                      for="fluePipeCapYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="fluePipeCapPending"
                      type="radio"
                      class="form__radio-input"
                      name="fluePipeCap"
                    />
                    <label
                      for="fluePipeCapPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="fluePipeCapNo"
                      type="radio"
                      class="form__radio-input"
                      name="fluePipeCap"
                    />
                    <label
                      for="fluePipeCapNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Gas Value</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="gasValueYes"
                      type="radio"
                      class="form__radio-input"
                      name="gasValue"
                      checked
                    />
                    <label
                      for="gasValueYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="gasValuePending"
                      type="radio"
                      class="form__radio-input"
                      name="gasValue"
                    />
                    <label
                      for="gasValuePending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="gasValueNo"
                      type="radio"
                      class="form__radio-input"
                      name="gasValue"
                    />
                    <label for="gasValueNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">IFM Capacitor</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="ifmCapacitorYes"
                      type="radio"
                      class="form__radio-input"
                      name="ifmCapacitor"
                      checked
                    />
                    <label
                      for="ifmCapacitorYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="ifmCapacitorPending"
                      type="radio"
                      class="form__radio-input"
                      name="ifmCapacitor"
                    />
                    <label
                      for="ifmCapacitorPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="ifmCapacitorNo"
                      type="radio"
                      class="form__radio-input"
                      name="ifmCapacitor"
                    />
                    <label
                      for="ifmCapacitorNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Indoor Fan Motor</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="indoorFanMotorYes"
                      type="radio"
                      class="form__radio-input"
                      name="indoorFanMotor"
                      checked
                    />
                    <label
                      for="indoorFanMotorYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="indoorFanMotorPending"
                      type="radio"
                      class="form__radio-input"
                      name="indoorFanMotor"
                    />
                    <label
                      for="indoorFanMotorPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="indoorFanMotorNo"
                      type="radio"
                      class="form__radio-input"
                      name="indoorFanMotor"
                    />
                    <label
                      for="indoorFanMotorNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Inducer Motor</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="inducerMotorYes"
                      type="radio"
                      class="form__radio-input"
                      name="inducerMotor"
                      checked
                    />
                    <label
                      for="inducerMotorYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="inducerMotorPending"
                      type="radio"
                      class="form__radio-input"
                      name="inducerMotor"
                    />
                    <label
                      for="inducerMotorPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="inducerMotorNo"
                      type="radio"
                      class="form__radio-input"
                      name="inducerMotor"
                    />
                    <label
                      for="inducerMotorNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Line Set</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="lineSetYes"
                      type="radio"
                      class="form__radio-input"
                      name="lineSet"
                      checked
                    />
                    <label
                      for="lineSetYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="lineSetPending"
                      type="radio"
                      class="form__radio-input"
                      name="lineSet"
                    />
                    <label
                      for="lineSetPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="lineSetNo"
                      type="radio"
                      class="form__radio-input"
                      name="lineSet"
                    />
                    <label for="lineSetNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Make-up Air</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="makeupAirYes"
                      type="radio"
                      class="form__radio-input"
                      name="makeupAir"
                      checked
                    />
                    <label
                      for="makeupAirYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="makeupAirPending"
                      type="radio"
                      class="form__radio-input"
                      name="makeupAir"
                    />
                    <label
                      for="makeupAirPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="makeupAirNo"
                      type="radio"
                      class="form__radio-input"
                      name="makeupAir"
                    />
                    <label for="makeupAirNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Manifold Pressure Tap</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="manifoldPressureTapYes"
                      type="radio"
                      class="form__radio-input"
                      name="manifoldPressureTap"
                      checked
                    />
                    <label
                      for="manifoldPressureTapYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="manifoldPressureTapPending"
                      type="radio"
                      class="form__radio-input"
                      name="manifoldPressureTap"
                    />
                    <label
                      for="manifoldPressureTapPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="manifoldPressureTapNo"
                      type="radio"
                      class="form__radio-input"
                      name="manifoldPressureTap"
                    />
                    <label
                      for="manifoldPressureTapNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Pilot</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="PilotYes"
                      type="radio"
                      class="form__radio-input"
                      name="Pilot"
                      checked
                    />
                    <label for="PilotYes" class="form__radio-label greenRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="PilotPending"
                      type="radio"
                      class="form__radio-input"
                      name="Pilot"
                    />
                    <label
                      for="PilotPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="PilotNo"
                      type="radio"
                      class="form__radio-input"
                      name="Pilot"
                    />
                    <label for="PilotNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Secondary Pan</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="secondaryPanYes"
                      type="radio"
                      class="form__radio-input"
                      name="secondaryPan"
                      checked
                    />
                    <label
                      for="secondaryPanYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="secondaryPanPending"
                      type="radio"
                      class="form__radio-input"
                      name="secondaryPan"
                    />
                    <label
                      for="secondaryPanPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="secondaryPanNo"
                      type="radio"
                      class="form__radio-input"
                      name="secondaryPan"
                    />
                    <label
                      for="secondaryPanNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Shut Off Value</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="shutOffValueYes"
                      type="radio"
                      class="form__radio-input"
                      name="shutOffValue"
                      checked
                    />
                    <label
                      for="shutOffValueYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="shutOffValuePending"
                      type="radio"
                      class="form__radio-input"
                      name="shutOffValue"
                    />
                    <label
                      for="shutOffValuePending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="shutOffValueNo"
                      type="radio"
                      class="form__radio-input"
                      name="shutOffValue"
                    />
                    <label
                      for="shutOffValueNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Temp Split</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="tempSplitYes"
                      type="radio"
                      class="form__radio-input"
                      name="tempSplit"
                      checked
                    />
                    <label
                      for="tempSplitYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="tempSplitPending"
                      type="radio"
                      class="form__radio-input"
                      name="tempSplit"
                    />
                    <label
                      for="tempSplitPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="tempSplitNo"
                      type="radio"
                      class="form__radio-input"
                      name="tempSplit"
                    />
                    <label for="tempSplitNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Thermostat</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="thermostatYes"
                      type="radio"
                      class="form__radio-input"
                      name="thermostat"
                      checked
                    />
                    <label
                      for="thermostatYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="thermostatPending"
                      type="radio"
                      class="form__radio-input"
                      name="thermostat"
                    />
                    <label
                      for="thermostatPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="thermostatNo"
                      type="radio"
                      class="form__radio-input"
                      name="thermostat"
                    />
                    <label
                      for="thermostatNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Transformer</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="transformerYes"
                      type="radio"
                      class="form__radio-input"
                      name="transformer"
                      checked
                    />
                    <label
                      for="transformerYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="transformerPending"
                      type="radio"
                      class="form__radio-input"
                      name="transformer"
                    />
                    <label
                      for="transformerPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="transformerNo"
                      type="radio"
                      class="form__radio-input"
                      name="transformer"
                    />
                    <label
                      for="transformerNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">TXV</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="txvYes"
                      type="radio"
                      class="form__radio-input"
                      name="txv"
                      checked
                    />
                    <label for="txvYes" class="form__radio-label greenRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="txvPending"
                      type="radio"
                      class="form__radio-input"
                      name="txv"
                    />
                    <label
                      for="txvPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="txvNo"
                      type="radio"
                      class="form__radio-input"
                      name="txv"
                    />
                    <label for="txvNo" class="form__radio-label redRadio">
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
              <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                <h6 class="mb-2">Visible Rust</h6>
                <div class="radioCheck customRadiocheck d-flex">
                  <div class="form__radio-group">
                    <input
                      id="visibleRustYes"
                      type="radio"
                      class="form__radio-input"
                      name="visibleRust"
                      checked
                    />
                    <label
                      for="visibleRustYes"
                      class="form__radio-label greenRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="visibleRustPending"
                      type="radio"
                      class="form__radio-input"
                      name="visibleRust"
                    />
                    <label
                      for="visibleRustPending"
                      class="form__radio-label yellowRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                  <div class="form__radio-group">
                    <input
                      id="visibleRustNo"
                      type="radio"
                      class="form__radio-input"
                      name="visibleRust"
                    />
                    <label
                      for="visibleRustNo"
                      class="form__radio-label redRadio"
                    >
                      <span class="form__radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomButtons d-flex justify-content-center mt-5">
          <button
            class="btn buttonDark d-flex align-items-center rounded me-2 text-uppercase"
          >
            Back
          </button>
          <button
            class="btn buttonPrimary d-flex align-items-center rounded text-uppercase"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<style>
.heading a img {
  width: 40px;
}
.indoorEquipHead img {
  width: 20px;
}
.indoorEquipHead p {
  font-size: 15px;
}
.indoorEquipSec .form-group h6 {
  font-size: 14px;
}
</style>
