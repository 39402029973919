<template>
  <!-- {{ details }} -->
  <div
    class="col-lg-7 col-md-9 col-sm-12 col-12 mx-auto"
    v-for="(item, index) in details.items"
    :key="index"
  >
    <div class="summeryDetailCard mb-4 px-3 py-4 rounded">
      <h6 class="mb-2 orangeText p-2 rounded secondaryLightbg">
        {{ item.heading }}
      </h6>
      <ul class="list-inline mb-0">
        <li class="py-2" v-for="(listItem, index) in item.data" :key="index">
          <div
            v-if="listItem.parts_count > 0"
            class="anchorModal d-flex align-items-start justify-content-between"
            @click="itemClicked(listItem)"
          >
            <div>
              <p class="mb-0">  <div v-html="listItem.option"></div> </p>
              <p class="greenText" v-if="listItem.is_service_warranty == 1">
                <img
                  src="../../assets/images/green-check-icon.png"
                  alt="eye-icon"
                  class="me-2"
                />Labor Warranty
              </p>
            </div>
            <h6 class="mb-0 py-1 px-4 rounded orangeText secondaryLightbg">
              <span class="fw-bold">
                {{ listItem.total_cost_text }}
                <img
                  src="../../assets/images/angle-orange-icon.png"
                  alt="eye-icon"
                />
              </span>
            </h6>
          </div>
          <div v-else class="d-flex align-items-start justify-content-between">
            <div>
              <p class="mb-0">  <div v-html="listItem.option"></div> </p>
              <p class="greenText"  v-if="listItem.is_service_warranty == 1">
                <img
                  src="../../assets/images/green-check-icon.png"
                  alt="eye-icon"
                  class="me-2"
                />Labor Warranty
              </p>
            </div>
            <h6 class="mb-0 py-1 px-4 rounded orangeText secondaryLightbg">
              <span class="fw-bold">
                {{ listItem.total_cost_text }}
              </span>
            </h6>
          </div>
        </li>
        <!-- <li class="d-flex align-items-center justify-content-between">
          <p class="mb-0">Electrical Connections</p>
          <h6 class="mb-0 py-1 px-4 orangeText lightGreyBg">$50</h6>
        </li> -->
      </ul>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="viewPartsModal"
    ref="viewPartsModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <!-- <div class="closebutton d-flex justify-content-end">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div> -->
          <div
            class="notesHead d-flex justify-content-between align-items-center primaryBg rounded p-2"
          >
            <h5 class="mb-0 whiteText fw-bold">Parts</h5>
            <div class="closebutton d-flex justify-content-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="modalCntnt">
            <nest_part_details_card
              :selectedItem="selectedItem"
              :parts="partsDetails"
              :isPartHeader="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nest_part_details_card from "./nest_part_details_card.vue";
import { mapActions, mapGetters } from "vuex";
import { Modal } from "bootstrap";

export default {
  components: {
    nest_part_details_card,
  },
  props: {
    details: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters(["partsDetails", "preChecksStore", "specialParts"]),
  },

  data() {
    return {
      jobId: this.$route.params.id,
      selectedItem: null
    };
  },

  watch: {
    partsDetails: function () {
      this.modal = new Modal(this.$refs.viewPartsModal);
      this.modal.show();
    },
  },

  methods: {
    ...mapActions([
      "getpartsDetails",
      "getSpecialParts",
      "storePreChecksDetails",
    ]),

    itemClicked(data) {
      this.selectedItem = data
      this.getpartsDetails({
        booking_diagnosis_option_id: data.booking_diagnosis_option_id,
        booking_id: this.jobId,
      });
    },
  },
};
</script>

<style>
.summeryDetailCard {
  box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.75);
}
/* .summeryDetailCard ul li{
  margin-bottom: 0.5rem;
} */
.summeryDetailCard ul li h6 {
  min-width: 120px;
  text-align: center;
  position: relative;
}
.summeryDetailCard ul li h6 img {
  position: absolute;
  top: 6px;
  right: 10px;
  transform: rotate(180deg);
  width: 8px;
}
.anchorModal {
  cursor: pointer;
}
#viewPartsModal .btn-close {
  filter: invert(1);
  opacity: 1 !important;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 0.5rem;
  border-width: 2px;
  font-size: 13px;
}
.summeryDetailCard li p img {
  width: 20px;
}
/* 
.summeryDetailCard ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.summeryDetailCard ul li {
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 0.5em;
}

.summeryDetailCard ul li:nth-child(n + 3) {
  display: none;
}

.summeryDetailCard ul li:first-child:nth-last-child(1),
.summeryDetailCard ul li:first-child:nth-last-child(1) ~ li {
  flex: 1 1 100%;
}

.summeryDetailCard ul li:first-child:nth-last-child(2),
.summeryDetailCard ul li:first-child:nth-last-child(2) ~ li {
  flex: 1 1 50%;
  display: block;
}

.summeryDetailCard ul li:first-child:nth-last-child(2) ~ li:nth-child(2n + 1) {
  border-left: none;
} */
</style>
