<template>
  <main class="outdoorEquipPg">
    <div class="container">
      <div :class="['outdoorEquipPgHead', 'sticky', { fixed: isFixed }]">
        <div class="heading d-flex align-items-center justify-content-between">
          <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
            <b>Equipment </b> - Job ID
            <span class="orangeText">{{ jobId }}</span>
          </h4>
          <nest_notes_modal />
          <!-- <a href="#" class="text-decoration-none">
            <span class="me-2 blackText">Notes</span>
            <img src="../../assets/images/notes-icon.png" alt="notes-icon" />
          </a> -->
        </div>
        <div class="breadcrumbs border rounded p-2 mb-4">
          <ul class="d-flex list-inline mb-0">
            <!--            <li><a class="greyText">My Account</a></li>-->
            <!--            <li>-->
            <!--              <a class="greyText">Service & Repair History</a>-->
            <!--            </li>-->

            <li>
              <a :href="'/job/detail/' + jobId" class="greyText">Job Details</a>
            </li>
            <li>
              <a :href="'/select/unit/' + jobId" class="greyText">Units</a>
            </li>
            <li class="blueText">Equipment Diagnosis - Job ID {{ jobId }}</li>
          </ul>
        </div>
        <div
          class="chipsSec"
          v-if="equipmentChecksList.indoor && equipmentChecksList.outdoor"
        >
          <ul class="list-inline mb-0 d-flex">
            <li
              :class="
                selectedTab == 'outdoor'
                  ? 'border py-1 px-2 me-2 active'
                  : 'border py-1 px-2 me-2'
              "
              @click="tabClick('outdoor')"
            >
              <a >Outdoor Unit</a>
            </li>
            <li
              :class="
                selectedTab == 'indoor'
                  ? 'border py-1 px-2 me-2 active'
                  : 'border py-1 px-2 me-2'
              "
              @click="tabClick('indoor')"
            >
              <a >Indoor Unit</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="outdorrEquipSec">
        <div
          class="outdorrEquipHead d-flex align-items-center justify-content-between my-4"
        >
          <h5 class="blueText mb-0">
            {{ bookingResponse.system_type }} System
          </h5>
          <ul class="list-inline mb-0 d-flex">
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/green-icon.png"
                class="me-2"
                alt="green-icon"
              />
              <p class="mb-0">Operating as per manufacturer's specification</p>
            </li>
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/duck-head-icon.png"
                class="me-2"
                alt="yellow-icon"
              />
              <p class="mb-0">
                Not operating as per manufacturer's specification
              </p>
            </li>
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/electric-head-icon.png"
                class="me-2"
                alt="red-icon"
              />
              <p class="mb-0">Non Operation / Critical</p>
            </li>
          </ul>
        </div>

        <div
          class="row"
          v-if="equipmentChecksList.indoor && equipmentChecksList.outdoor"
        >
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div
              class="section outdoorUnit mt-5"
              id="outdoor"
              @scroll="onScroll"
            >
              <div v-if="hasFixedClassOutdoor" class="minHeight"></div>
              <h5 class="primaryBg whiteText p-2 rounded">Outdoor Unit</h5>
              <div class="row">
                <div
                  class="col-lg-4 col-md-6 col-sm-4 col-6"
                  v-for="(item, index) in outdoorOptions"
                  :key="index"
                >
                  <div
                    :id="'outdoor-' + index"
                    class="form-group mb-3 lightGreyBg px-2 py-3 rounded"
                    :class="{ highlight: item.highlight }"
                  >
                    <h6 class="mb-2">{{ item.option }}</h6>
                    <div
                      class="radioCheck customRadiocheck d-flex justify-content-evenly"
                    >
                      <div class="form__radio-group">
                        <input
                          v-on:change="onChange(item, 1)"
                          :checked="item.value == 1"
                          type="radio"
                          class="form__radio-input"
                          :value="1"
                          :id="item.diagnostic_option_id + '-green'"
                          :name="item.diagnostic_option_id"
                        />
                        <label
                          :for="item.diagnostic_option_id + '-green'"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          v-on:change="onChange(item, 2)"
                          :checked="item.value == 2"
                          type="radio"
                          class="form__radio-input"
                          :value="2"
                          :id="item.diagnostic_option_id + '-yellow'"
                          :name="item.diagnostic_option_id"
                        />
                        <label
                          :for="item.diagnostic_option_id + '-yellow'"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          v-on:change="onChange(item, 3)"
                          :checked="item.value == 3"
                          type="radio"
                          class="form__radio-input"
                          :value="3"
                          :id="item.diagnostic_option_id + '-red'"
                          :name="item.diagnostic_option_id"
                        />
                        <label
                          :for="item.diagnostic_option_id + '-red'"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="section indoorUnit mt-5" id="indoor">
              <div v-if="hasFixedClassIndoor" class="minHeight"></div>
              <h5 class="primaryBg whiteText p-2 rounded">Indoor Unit</h5>

              <div class="row">
                <div
                  class="col-lg-4 col-md-6 col-sm-4 col-6"
                  v-for="(item, index) in indoorOptions"
                  :key="index"
                >
                  <div
                    :id="'indoor-' + index"
                    class="form-group mb-3 lightGreyBg px-2 py-3 rounded"
                    :class="{ highlight: item.highlight }"
                  >
                    <h6 class="mb-2">{{ item.option }}</h6>
                    <div
                      class="radioCheck customRadiocheck d-flex justify-content-evenly"
                    >
                      <div class="form__radio-group">
                        <input
                          v-on:change="onChange(item, 1)"
                          :checked="item.value == 1"
                          type="radio"
                          class="form__radio-input"
                          :value="1"
                          :id="item.diagnostic_option_id + '-green'"
                          :name="item.diagnostic_option_id"
                        />
                        <label
                          :for="item.diagnostic_option_id + '-green'"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          v-on:change="onChange(item, 2)"
                          :checked="item.value == 2"
                          type="radio"
                          class="form__radio-input"
                          :value="2"
                          :id="item.diagnostic_option_id + '-yellow'"
                          :name="item.diagnostic_option_id"
                        />
                        <label
                          :for="item.diagnostic_option_id + '-yellow'"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          v-on:change="onChange(item, 3)"
                          :checked="item.value == 3"
                          type="radio"
                          class="form__radio-input"
                          :value="3"
                          :id="item.diagnostic_option_id + '-red'"
                          :name="item.diagnostic_option_id"
                        />
                        <label
                          :for="item.diagnostic_option_id + '-red'"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="section outdoorUnit" id="outdoorSec">
              <div v-if="hasFixedClass" class="minHeight"></div>
              <h5 class="primaryBg whiteText p-2 rounded">{{ getUnitName(outdoorOptions) }} Unit</h5>
              <div class="row">
                <div
                  class="col-lg-2 col-md-4 col-sm-6 col-6"
                  v-for="(item, index) in outdoorOptions"
                  :key="index"
                >
                  <div
                    :id="'outdoor-' + index"
                    class="form-group mb-3 lightGreyBg px-2 py-3 rounded"
                    :class="{ highlight: item.highlight }"
                  >
                    <h6 class="mb-2">{{ item.option }}</h6>
                    <div
                      class="radioCheck customRadiocheck d-flex justify-content-evenly"
                    >
                      <div class="form__radio-group">
                        <input
                          v-on:change="onChange(item, 1)"
                          :checked="item.value == 1"
                          type="radio"
                          class="form__radio-input"
                          :value="1"
                          :id="item.diagnostic_option_id + '-green'"
                          :name="item.diagnostic_option_id"
                        />
                        <label
                          :for="item.diagnostic_option_id + '-green'"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          v-on:change="onChange(item, 2)"
                          :checked="item.value == 2"
                          type="radio"
                          class="form__radio-input"
                          :value="2"
                          :id="item.diagnostic_option_id + '-yellow'"
                          :name="item.diagnostic_option_id"
                        />
                        <label
                          :for="item.diagnostic_option_id + '-yellow'"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          v-on:change="onChange(item, 3)"
                          :checked="item.value == 3"
                          type="radio"
                          class="form__radio-input"
                          :value="3"
                          :id="item.diagnostic_option_id + '-red'"
                          :name="item.diagnostic_option_id"
                        />
                        <label
                          :for="item.diagnostic_option_id + '-red'"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomButtons d-flex justify-content-center mt-5">
        <button
          @click="backButtonClicked()"
          class="btn buttonDark d-flex align-items-center rounded me-2 text-uppercase rounded"
        >
          Back
        </button>
        <button
          @click="nextButtonClicked()"
          class="btn buttonPrimary d-flex align-items-center rounded text-uppercase rounded"
        >
          Next
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import nest_notes_modal from "../../components/nest_components/nest_notes_modal.vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    nest_notes_modal,
  },

  computed: {
    ...mapGetters([
      "equipmentChecksList",
      "apiCallback",
      "storeChecksResponse",
      "getDiagnosisOptions",
      "bookingResponse",
    ]),
    hasFixedClassOutdoor() {
      return this.minHeightOutdoor;
    },
    hasFixedClassIndoor() {
      return this.minHeightIndoor;
    },
  },

  data() {
    return {
      selectedTab: "outdoor",
      jobId: this.$route.params.id,
      unit_id: this.$route.query.unit_id,
      // Indoor
      indoorOptions: [],
      indoorExistingOptions: [],
      // Outdoor
      outdoorOptions: [],
      outdoorExistingOptions: [],
      isFixed: false,
      minHeightOutdoor: false,
      minHeightIndoor: false,
      pageOffset: 0,
      offsetTop:0
    };
  },

  mounted() {
    this.getBookingDetails({
      booking_id: this.jobId,
    });
    window.addEventListener("scroll", this.updateOffset);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  watch: {
    equipmentChecksList: function () {
      this.indoorOptions =
        (this.equipmentChecksList.indoor &&
          this.equipmentChecksList.indoor.body) ??
        [];
      this.outdoorOptions =
        (this.equipmentChecksList.outdoor &&
          this.equipmentChecksList.outdoor.body) ??
        [];
      this.getDiagnosisOption({
        booking_id: this.jobId,
        diagnosis_type: "equipment_checks",
      });
    },

    getDiagnosisOptions: function () {
      this.handleDiagnosisResponse();
    },

    storeChecksResponse: function () {
      if (this.storeChecksResponse.diagnosis_type == "diagnosis_items") {
        this.$router.push({
          path: `/summary/${this.$route.params.id}`,
          query: {
            unit_id: this.unit_id,
            is_from_mobile: this.$route.query.is_from_mobile,
          },
        });
      } else {
        this.storeChecks({
          booking_id: this.jobId,
          diagnosis_type: "diagnosis_items",
          diagnosis_data: JSON.stringify({ diagnosis_items: [] }),
        });
      }
    },
    bookingResponse: function () {
      this.handleBookingResponse();
    },
  },

  methods: {
    updateOffset() {
      // this.pageOffset =
      //   window.pageYOffset || document.documentElement.scrollTop;
      // console.log(this.pageOffset);
      // if(this.pageOffset <= 100){
      //   this.tabClick('outdoor')
      // }
    },

  
    ...mapActions([
      "getEquipmentChecks",
      "storeChecks",
      "getDiagnosisOption",
      "getBookingDetails",
    ]),

    tabClick(tab) {
      this.selectedTab = tab;
      if (tab === "outdoor") {
        this.minHeightOutdoor = true;
        this.minHeightIndoor = false;
      } else if (tab === "indoor") {
        this.minHeightOutdoor = false;
        this.minHeightIndoor = true;
      }

      const element = document.getElementById(tab);
      if (element) {
        const rect = element.getBoundingClientRect();
        let offsetTop = rect.top + window.pageYOffset;
    

        window.scrollTo({
          top: offsetTop - 250,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    handleBookingResponse() {
      if (this.bookingResponse !== null) {
        this.getEquipmentChecks({
          booking_id: this.jobId,
          unit_id: this.unit_id,
        });
        window.addEventListener("scroll", this.handleScroll);
      }
    },
    onChange(data, value) {
      data.highlight = false;
      data.value = value;
    },

    handleDiagnosisResponse() {
      if (this.getDiagnosisOptions[0].diagnosis_type == "equipment_checks") {
        this.setIndoorOptions();
        this.setOutdoorOptions();
      }
    },

    setIndoorOptions() {
      let diagnosis_data = JSON.parse(
        this.getDiagnosisOptions[0].diagnosis_data
      );
      this.indoorExistingOptions = diagnosis_data.indoor;
      let self = this;

      this.indoorOptions = this.indoorOptions.map(function (item) {
        self.indoorExistingOptions.forEach(function (obj) {
          if (
            parseInt(obj.diagnostic_option_id) ===
            parseInt(item.diagnostic_option_id)
          ) {
            item.value = obj.value;
          }
        });
        return item;
      });
    },

    setOutdoorOptions() {
      let diagnosis_data = JSON.parse(
        this.getDiagnosisOptions[0].diagnosis_data
      );
      this.outdoorExistingOptions = diagnosis_data.outdoor;
      let self = this;

      this.outdoorOptions = this.outdoorOptions.map(function (item) {
        self.outdoorExistingOptions.forEach(function (obj) {
          if (
            parseInt(obj.diagnostic_option_id) ===
            parseInt(item.diagnostic_option_id)
          ) {
            item.value = obj.value;
          }
        });
        return item;
      });
    },

    getUnitName(data){
      let res = ''
      if (data && data.length > 0){
        res = data[0].type_formatted
      }
      return res
    },
    nextButtonClicked() {
      // let indoor = this.checkOudoorValidation();
      // let outdoor = this.checkIndoorValidation();

      let indoorOutdoor = this.checkIndoorOudoorValidation();

      if (this.bookingResponse.system_type == "Split" && !indoorOutdoor) {
        toast.error("Select all item.");
      } else if (
        this.bookingResponse.system_type == "Package" &&
        !indoorOutdoor
      ) {
        toast.error("Select all item.");
      } else {
        this.storeChecks({
          booking_id: this.jobId,
          diagnosis_type: "equipment_checks",
          diagnosis_data: JSON.stringify({
            indoor: this.indoorOptions,
            outdoor: this.outdoorOptions,
          }),
        });
      }
    },

    checkIndoorValidation() {
      let res = true;
      for (var key in this.indoorOptions) {
        let item = this.indoorOptions[key];
        if (item.value == 0) {
          item.highlight = true;
          res = false;
        }
      }
      return res;
    },

    checkOudoorValidation() {
      let res = true;
      for (var key1 in this.outdoorOptions) {
        let item = this.outdoorOptions[key1];
        if (item.value == 0) {
          item.highlight = true;
          res = false;
        }
      }
      return res;
    },

    checkIndoorOudoorValidation() {
      let res = true;
      let index = -1;

      for (let key in this.outdoorOptions) {
        let item = this.outdoorOptions[key];
        if (item.value == 0) {
          item.highlight = true;
          res = false;
          if (index == -1) {
            index = "outdoor-" + key;
          }
        }
      }

      for (let key in this.indoorOptions) {
        let item = this.indoorOptions[key];
        if (item.value == 0) {
          item.highlight = true;
          res = false;
          if (index == -1) {
            index = "indoor-" + key;
          }
        }
      }

      if (!res) {
        window.scrollTo({
          top: document.getElementById("" + index).offsetTop - 180,
          left: 0,
          behavior: "smooth",
        });
      }

      return res;
    },

    getSelectedIndoorOptions() {
      return this.indoorOptions.filter(function (item) {
        return item.value != 1 && item.value != 0;
      });
    },

    getSelectedOutdoorOptions() {
      return this.outdoorOptions.filter(function (item) {
        return item.value != 1 && item.value != 0;
      });
    },

    backButtonClicked() {
      this.$router.go(-1);
    },
    onScroll(e) {
      console.log("bottom!", e);
    },
    handleScroll() {
      const scroll = window.scrollY;
      this.isFixed = scroll >= 100;
      const sections = document.querySelectorAll(".section");
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          this.selectedTab = section.id.replace("Sec", "");
        }
      });
    },
  },
};
</script>

<style>
.heading a img {
  width: 40px;
}

.highlight {
  border: 2px solid red;
  padding: 10px;
}
.minHeight {
  min-height: 0px;
}
</style>
