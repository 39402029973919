<template>
  <div class="row">
    <div
      @click="selectItem(item)"
      v-for="(item, index) in data"
      :key="index"
      class="col-lg-4 col-md-6 col-sm-12 col-12"
    >
      <div class="nestCard border rounded p-4">
        <div
          class="nestCardIcon d-flex align-items-center justify-content-center mb-4"
        >
          <div class="cardIcon">
            <img :src="item.display_image" class="mx-auto d-table w-100" alt="" />
          </div>

          <h6 class="mb-0 primaryBg whiteText text-center">10</h6>
        </div>
        <div class="nestCardDet text-center">
          <h5 class="mb-4 blackText">{{ item.title }}</h5>
          <a href="">
            <img
              src="../../assets/images/next-icon.png"
              class="nextIcon"
              alt=""
            />
            <img
              src="../../assets/images/next-hover-icon.png"
              class="nextHoverIcon"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      data: [],
    };
  },

  watch: {
    dashboardData: function () {
      this.data = this.dashboardData.navigation_items;
    },
  },

  computed: {
    ...mapGetters(["dashboardData"]),
  },

  mounted() {
    this.getData();
  },

  methods: {
    ...mapActions(["getDashboardData"]),

    selectItem(item) {
      if (item.slug == "sar-history") {
        this.$router.push({
          path: `service-repair-history`,
        });
      }
    },

    getData() {
      this.getDashboardData();
    },
  },
};
</script>
