<template>
  <div class="container pt-4">
    <AlertNote v-if="checkNoteAlert()"></AlertNote>
    <QuoteInfo v-if="isShowQuoteInfo"></QuoteInfo>
    <div class="row">
      <div class="col-12">
        <div class="actionQouteBtn">
          <h3 class="primaryColor fw-bold">Quote Actions:</h3>
          <div class="btn-group" v-for="(action, index) in actionButtons" :key="index">
            <button @click="actionButtonClicked(action)" :class="index % 2 == 0 ? 'btn primaryBtn me-2' : 'btn secondaryBtn me-2'">
              {{ action }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade modal-lg" ref="modal" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false"
      tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <QuoteModal :quoteSummary="summary" :readOnly="true"></QuoteModal>
      </div>
    </div>

    <div class="modal fade modal-lg" ref="cancelQuote" id="exampleModal" data-bs-backdrop="static"
      data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <CancelQuoteDialog @event="CancelCallback"></CancelQuoteDialog>
      </div>
    </div>

    <div class="modal fade modal-lg" ref="cancelQuoteI" id="exampleModal" data-bs-backdrop="static"
      data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <CancelQuoteDialogI @event="CancelCallback"></CancelQuoteDialogI>
      </div>
    </div>

    <div class="modal fade modal-lg" ref="qrDialog" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false"
      tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <QRDialog v-if="qrCodeCounter > 0" @event="QRCallback"></QRDialog>
      </div>
    </div>

    <div class="modal fade" ref="shareQuote" id="shareQuote" data-bs-backdrop="static" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content shareModal">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Send Quote</h5>
            <button type="button" class="btn-close-Pop" data-bs-dismiss="modal" aria-label="Close">
              <img src="@/assets/images/cross-btn.png" class="img-fluid" />
            </button>
          </div>

          <div class="modal-body">
            <p class="text-center">
              Please enter the email address or phone number below to send quote
            </p>
            <div class="mb-3">
              <label for="recipient-name" class="col-form-label">Email Address:</label>
              <input placeholder="Enter Email address" v-model="email" type="text" class="form-control"
                id="recipient-name" />
            </div>
            <div class="mb-3">
              <label for="message-text" class="col-form-label">Phone Number:</label>
              <input v-model="phone" placeholder="Enter Phone Number" @input="acceptNumber" type="text"
                class="form-control" id="recipient-name" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn main-button m-2" data-bs-dismiss="modal">
              Cancel
            </button>
            <button @click="onSubmit()" type="button" class="btn main-button m-2">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import QuoteModal from "@/components/quote_modal/QuoteModal.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
import { Modal } from "bootstrap";
import QuoteInfo from "@/components/quote/QuoteInfo.vue";
import AlertNote from "@/components/alert_note/AlertNote.vue";
import CancelQuoteDialog from "@/components/tradecall_dialog/CancelQuoteDialog.vue";
import CancelQuoteDialogI from "@/components/tradecall_dialog/CancelQuoteDialog_.vue";
import QRDialog from "@/components/qr_replacement/QRDialog.vue";

export default {
  components: {
    QuoteInfo,
    QuoteModal,
    CancelQuoteDialog,
    CancelQuoteDialogI,
    QRDialog,
    AlertNote,
  },

  data() {
    return {
      summary: null,
      actionButtons: [],
      isShowQuoteInfo: false,
      timer: null,
      phone: "",
      email: "",
      qrCodeCounter: 1
    };
  },
  computed: {
    ...mapGetters([
      "qrResponse",
      "quoteEmailResponse",
      "quoteDetail",
      "quoteSummary",
      "checkoutResponse",
      "cancelReasons",
    ]),
  },

  watch: {
    qrResponse: function (data) {
      console.log("resssss", data);

      this.qrCodeCounter = this.qrCodeCounter + 1

      this.modal = new Modal(this.$refs.qrDialog);
      this.modal.show();
    },

    quoteEmailResponse: function (data) {
      toast.success(data.message);
      this.modal.hide();
    },
    quoteDetail: function (data) {

      this.isShowQuoteInfo = true;
      this.actionButtons = [];

      console.log("data", data)
      if (data.items.length > 0) {
        this.actionButtons.push("View Quote");
      }
      if (data.status.technician.status == 0) {
        this.actionButtons.push("Edit Quote");
        //  this.actionButtons.push("QR Code");
        //        this.actionButtons.push("Cancel");
        if (data.partner_info.is_allied == "1") {
          this.getReasonsCall();
        }
      } else if (data.status.technician.status == 2) {
        this.actionButtons.push("Pause Job");
        this.actionButtons.push("Start Job");
        //  this.actionButtons.push("QR Code");
      } else if (data.status.technician.status == 6) {
        this.actionButtons.push("Resume Job");
        //  this.actionButtons.push("QR Code");
      } else if (data.status.technician.status == 7) {
        this.actionButtons.push("Pause Job");
        this.actionButtons.push("Complete Job");
        //     this.actionButtons.push("QR Code");
      } else if (data.status.technician.status == 3) {
        this.checkoutAcknowledge({
          manual_quote_id: this.$route.params.id,
          decision: "yes",
        });
      } else if (data.status.technician.status == 4) {
        this.startQuote({
          manual_quote_id: this.$route.params.id,
        });
      }


      if (data.is_quote_submitted == 1) {

        this.actionButtons.push("Share Quote");
        this.actionButtons.push("QR Code");

      }

      if (data.status.technician.status == 0 && data.is_quote_submitted == 1) {
        this.actionButtons.push("Cancel Quote");
      }
    },
    quoteSummary: function (data) {
      this.summary = data;
      this.modal = new Modal(this.$refs.modal);
      this.modal.show();
    },

    checkoutResponse: function () {
      this.getQuoteDetail({
        manual_quote_id: this.$route.params.id,
      });
    },
  },

  mounted() {

    this.timer = setInterval(() => {
      this.getQuoteDetail({
        manual_quote_id: this.$route.params.id,
        is_hide: true,
      });
    }, 15000);

    this.getQuoteDetail({
      manual_quote_id: this.$route.params.id,
    });
    if (this.$route.query.is_edit == 1) {
      localStorage.setItem(this.$route.params.id + "-quote", null);
      this.$router.push({
        path: `/quote/edit/${this.$route.params.id}`,
        query: {
          is_edit: 1,
        },
      });
    }
  },

  beforeRouteLeave() {
    clearInterval(this.timer);
  },

  methods: {
    ...mapActions([
      "sendQuoteToEmail",
      "setCommponent",
      "getQuoteDetail",
      "postCancelQuote",
      "getQuoteSummary",
      "startQuote",
      "pauseQuote",
      "completeQuote",
      "checkoutAcknowledge",
      "getReasons",
      "getQRCode",
    ]),

    checkNoteAlert() {
      if (this.quoteDetail &&
        this.quoteDetail.partner_info
        && this.quoteDetail.partner_info.show_replacement_opportunity_badge == '1'
        && this.quoteDetail.partner_info.status == '1'
        && this.quoteDetail.partner_info.company_name != 'Vivint'
        && this.quoteDetail.partner_info.replacement_opportunity_number.length > 0) {
        return true
      } else {
        return false
      }
    },

    onSubmit() {
      if (this.isValid()) {
        this.sendQuoteToEmail({
          manual_quote_id: this.$route.params.id,
          email: this.email,
          phone: this.phone,
        });
      }
    },

    getReasonsCall() {
      this.getReasons();
    },

    isValid() {
      var status = true;

      if (this.email.length <= 0 && this.phone.length <= 0) {
        toast.error(
          "Please enter the email address or phone number below to send quote."
        );
        status = false;
      } else {
        if (!this.validateEmail() && this.email.length > 0) {
          toast.error("Email address not valid.");
          status = false;
        }

        if (this.phone.length < 14 && this.phone.length > 0) {
          toast.error("Phone number not valid.");
          status = false;
        }
      }

      return status;
    },

    validateEmail() {
      var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return regex.test(this.email);
    },
    acceptNumber() {
      var x = this.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },

    CancelCallback(opt, data) {
      if (opt == "close") {
        this.modal.hide();
      } else if (opt == "confirm") {
        data.manual_quote_id = this.$route.params.id;
        this.modal.hide();
        this.postCancelQuote(data);
      }
    },

    QRCallback(opt) {
      if (opt == "close") {
        this.modal.hide();
      } else if (opt == "confirm") {
        this.modal.hide();
      }
    },

    qrCodeAppiCall() {
      this.getQRCode({
        job_id: this.quoteDetail.id,
        job_source: "iq",
      });
    },
    actionButtonClicked(action) {
      if (action == "Edit Quote") {
        localStorage.setItem(this.$route.params.id + "-quote", null);
        this.$router.push({
          path: `/quote/edit/${this.$route.params.id}`,
        });
      } else if (action == "View Quote") {
        var params = {
          manual_quote_id: this.quoteDetail.id,
        };

        params["status"] = "submitted";

        this.getQuoteSummary(params);
      } else if (action == "Cancel Quote") {
        if (this.quoteDetail.partner_info.is_allied == "1") {
          this.modal = new Modal(this.$refs.cancelQuoteI);
          this.modal.show();
        } else {
          this.modal = new Modal(this.$refs.cancelQuote);
          this.modal.show();
        }
      } else if (action == "Start Job") {
        this.startQuote({
          manual_quote_id: this.$route.params.id,
        });
      } else if (action == "Resume Job") {
        this.startQuote({
          manual_quote_id: this.$route.params.id,
        });
      } else if (action == "Pause Job") {
        this.pauseQuote({
          manual_quote_id: this.$route.params.id,
        });
      } else if (action == "Complete Job") {
        this.completeQuote({
          manual_quote_id: this.$route.params.id,
        });
      } else if (action == "Share Quote") {

        this.email = this.quoteDetail.consumer.email ?? ""
        this.phone = this.quoteDetail.consumer.phone ?? ""

        this.modal = new Modal(this.$refs.shareQuote);
        this.modal.show();
      } else if (action == "QR Code") {
        this.qrCodeAppiCall();
      }
    },
  },
};
</script>

<style>
.primaryBtn {
  background: #fe5000 !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 5px #999 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 10px 30px !important;
}

.secondaryBtn {
  background: #003087 !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 5px #999 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 10px 30px !important;
}

.shareModal .modal-header {
  background: #003087;
  text-align: center;
  color: #fff;
  justify-content: space-between;
  display: block;
}

.shareModal .modal-footer {
  justify-content: center;
}

.shareModal .modal-footer button {
  border-radius: 8px;
  padding: 10px 30px;
  background: #fe5000;
  border: none;
}

.shareModal .modal-footer button:hover {
  background: #003087;
}

.shareModal .modal-footer button.btn-secondary {
  background: #003087;
}

.btn-close-Pop {
  background: none;
  border: 0;
  position: absolute;
  top: 15px;
  right: 10px;
}

.shareModal label {
  color: #fe5000;
}

.actionQouteBtn {
  text-align: center;
}

.actionQouteBtn h3 {
  color: #003087;
  margin-bottom: 25px;
  border-bottom: 2px solid #00308726;
  padding-bottom: 15px;
  text-align: left;
}

@media screen and (max-width: 992px) {
  .actionQouteBtn .btn-group button {
    margin-bottom: 0.5rem;
    font-size: 16px;
    padding: 0.5rem 1.2rem;
  }
}

@media screen and (max-width: 575px) {
  .actionQouteBtn .btn-group button {
    margin-bottom: 0.5rem;
    font-size: 15px;
    padding: 0.5rem 1rem;
  }
}
</style>
