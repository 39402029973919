<template>
  <div id="app">
    <layout />
  </div>
</template>

<script>
import Layout from "./components/layouts/Layout.vue";

export default {
  name: "VueCRUD",
  components: {
    Layout,
  },
};
</script>