<template>
  <div class="nestTimeline">
    <h6 class="mb-4 blackText fw-bold">Unit Information</h6>
    <ul class="list-inline mb-0">
      <li class="active d-flex align-items-center pb-4">
        <span>1</span>
        <h6 class="mb-0 greyText">Initial Check</h6>
      </li>
      <!-- <li class="d-flex align-items-center pb-4">
        <span>2</span>
        <h6 class="mb-0 greyText">Pre Check</h6>
      </li> -->
      <li class="d-flex align-items-center pb-4">
        <span>2</span>
        <h6 class="mb-0 greyText">Indoor Unit Diagnosis</h6>
      </li>
      <li class="d-flex align-items-center pb-4">
        <span>3</span>
        <h6 class="mb-0 greyText">Outdoor Unit Diagnosis</h6>
      </li>
      <li class="d-flex align-items-center pb-4">
        <span>4</span>
        <h6 class="mb-0 greyText">Additional Part Level Evaluation</h6>
      </li>
      <li class="d-flex align-items-center pb-4">
        <span>5</span>
        <h6 class="mb-0 greyText">Finalization of Evaluation</h6>
      </li>
      <li class="d-flex align-items-center">
        <span>6</span>
        <h6 class="mb-0 greyText">Diagnosis Sumamry</h6>
      </li>
    </ul>
  </div>
</template>

<style>

</style>
