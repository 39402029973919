<template>
  <main class="editSummaryPg">
    <div class="container" v-if="isApiCalled">
      <div class="heading">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          <b>Edit Evaluation </b> - Job ID
          <span class="orangeText">{{ jobId }}</span>
        </h4>
      </div>
      <div class="breadcrumbs border rounded p-2 mb-4">
        <ul class="d-flex list-inline mb-0">
          <li>
            <a :href="'/job/detail/' + jobId" class="greyText">Job Details</a>
          </li>
          <li class="blueText">Edit Evaluation - Job ID {{ jobId }}</li>
        </ul>
      </div>
      <div class="editSummarySec">
        <div class="addItemLinkDiv">
          <a
            @click="addItemClicked()"
            class="d-flex align-items-center justify-content-end text-decoration-none blackText addItemLink"
            >Add Items
            <img
              src="../../assets/images/add-green-circle.png"
              alt="add-icon"
              class="ms-1"
          /></a>
        </div>
        <div class="clearfix"></div>
        <div class="row">
          <div
            class="col-lg-6 col-md-6 col-sm-12 col-12"
            v-for="(item, index) in options"
            :key="index"
          >
            <nest_edit_summary_card
              @event="callback"
              :item="item"
              :index="index"
              :bookingServices="bookingServices"
              :bookingParts="bookingParts"
              :bookingDiagnosisOptions="bookingDiagnosisOptions"
            />
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <nest_aval_part_card
              :bookingDiagnosisItems="bookingDiagnosisItems"
              :options="options"
              :storeObject="storeObject"
            />
          </div>
        </div>
      </div>
      <div
        class="bottomButtons border-top d-flex justify-content-center pt-4 mt-4"
      >
        <button
          @click="backButtonClicked()"
          class="btn buttonDark d-flex align-items-center me-2 text-uppercase rounded"
        >
          Back
        </button>
        <button
          @click="nextButtonClicked()"
          class="btn buttonPrimary d-flex align-items-center text-uppercase rounded"
        >
          Next
        </button>
      </div>
    
    </div>
  </main>
</template>

<script>
import nest_edit_summary_card from "../../components/nest_components/nest_edit_summary_card.vue";
import nest_aval_part_card from "../../components/nest_components/nest_aval_part_card.vue";
import { mapActions, mapGetters } from "vuex";

import moment from "moment";

export default {
  watch: {
    bookingDiagnosisItems: function () {
      this.options =
        this.bookingDiagnosisItems.diagnostic_options_required.concat(
          this.bookingDiagnosisItems.diagnostic_options_recommended
        );
      this.options.forEach((element) => {
        let arr = [];
        if (element.value == 3) {
          arr = this.bookingServices.actions.required;
        } else {
          arr = this.bookingServices.actions.recommended;
        }
        arr.forEach((obj) => {
          if (element.booking_action_id == obj.id) {
            element.selected_service = obj;
          }
        });
      });

      this.getDiagnosisOption({
        booking_id: this.jobId,
        diagnosis_type: "edit_diagnosis_items",
      });
    },

    bookingServices: function () {
      this.getBookingDiagnosisOptions({
        booking_id: this.$route.params.id,
      });
    },

    bookingDiagnosisOptions: function () {
      this.getBookingDiagnosisItems({
        booking_id: this.$route.params.id,
      });
    },

    storeChecksResponse: function () {
      let payload = {
        booking_id: this.$route.params.id,
        additional_items: JSON.stringify(
          this.bookingDiagnosisItems.additional_items
        ),
        diagnostic_options: JSON.stringify(this.options),
        recommended_discount: this.storeObject.recommended_discount ?? 0,
        claim_status: 0,
      };

      if (this.storeObject.isSpecial) {
        let data = moment(this.storeObject["parts_datetime"]).unix();
        payload["parts_datetime"] = data;
      }

      this.updateCollectData(payload);
    },

    updateCollectDataResponse: function () {
      this.$router.push({
        path: `/summary-detail/${this.$route.params.id}`,
        query: { is_update: 1 },
      });
    },

    getDiagnosisOptions: function () {
      this.isApiCalled = true;
      this.setPresistData();
    },

    bookingResponse: function () {
      this.handleBookingResponse();
    },
  },

  components: {
    nest_edit_summary_card,
    nest_aval_part_card,
  },

  computed: {
    ...mapGetters([
      "jobDetail",
      "bookingDiagnosisItems",
      "bookingServices",
      "bookingParts",
      "bookingDiagnosisOptions",
      "storeChecksResponse",
      "getDiagnosisOptions",
      "updateCollectDataResponse",
      "bookingResponse",
    ]),
  },

  data() {
    return {
      jobId: this.$route.params.id,
      options: [],
      storeObject: { isSpecial: false, recommended_discount: 0 },
      isApiCalled: false,
      isLoaded: false,
    };
  },

  mounted() {
    this.getBookingDetails({
      booking_id: this.jobId,
    });
  },

  methods: {
    ...mapActions([
      "getBookingDiagnosisItems",
      "getBookingServices",
      "getBookingParts",
      "getBookingDiagnosisOptions",
      "storeChecks",
      "getDiagnosisOption",
      "updateCollectData",
      "getBookingDetails",
    ]),

    handleBookingResponse() {
      if (this.bookingResponse !== null) {
        this.storeObject.recommended_discount =
          this.bookingResponse.recommended_discount;
        this.getBookingServices({ booking_id: this.jobId });
        this.getBookingParts({
          category_id: "0",
          booking_id: this.jobId,
        });
      }
    },

    addItemClicked() {
      this.options.unshift({ value: 2, diagnostic_option_id: null });
    },
    callback(index) {
      this.$dialog.show({
        title: "Remove Item",
        text: "Are you sure you want to remove this item?",
        onConfirm: () => {
          this.options.splice(index, 1);
        },
      });
    },

    nextButtonClicked() {
      let res = true;
    //  let msg = "";
      let index = -1;

      for (let indx in this.options) {
        let element = this.options[indx];

        if (!element.option || element.option.length <= 0) {
       //   msg = "Title field is required.";
          this.options[indx]["is_error_title"] = true;
          res = false;
          if (index == -1) {
            index = indx;
          }
        }
        if (
          element.is_service_fee_charges_field != 1 &&
          !element.selected_service
        ) {
      //    msg = "Please select action.";
          this.options[indx]["is_error_action"] = true;
          res = false;
          if (index == -1) {
            index = indx;
          }
        }
        if (
          element.service_fee + "" == "undefined" ||
          element.service_fee + "" == ""
        ) {
       //   msg = "Service Fee field is required.";
          this.options[indx]["is_error_serviceFee"] = true;
          res = false;
          if (index == -1) {
            index = indx;
          }
        }
        if (
          element.booking_action_id == 2 &&
          (!element.parts || element.parts.length < 1)
        ) {
         // msg = "Parts is required..";
          this.options[indx]["is_error_parts"] = true;
          res = false;
          if (index == -1) {
            index = indx;
          }
        }
      }

      if (!res) {
        window.scrollTo({
          top: document.getElementById("" + index).offsetTop,
          left: 0,
          behavior: "smooth",
        });
      } else {
        if (this.storeObject.isSpecial && !this.storeObject["parts_datetime"]) {
          this.storeObject["is_error_parts_datetime"] = true
          res = false;
          if (index == -1) {
            index = 'parts_datetime';
          }
        } 
        // else if (
        //   this.storeObject.recommended_discount &&
        //   this.storeObject.recommended_discount.length <= 0
        // ) {
        //   this.storeObject["recommended_discount"] = true
        //   res = false;
        //   if (index == -1) {
        //     index = 'recommended_discount';
        //   }
        // }

        if (res) {
          this.storeChecks({
            booking_id: this.jobId,
            diagnosis_type: "edit_diagnosis_items",
            diagnosis_data: JSON.stringify({
              diagnosis_items: this.options,
              additional_items: this.bookingDiagnosisItems.additional_items,
            }),
          });
        } else {
          window.scrollTo({
          top: document.getElementById("" + index).offsetTop,
          left: 0,
          behavior: "smooth",
        });
        }
      }
    },

    backButtonClicked() {
      this.$router.go(-1);
    },

    setPresistData() {
      let diagnosis_data = JSON.parse(
        this.getDiagnosisOptions[0].diagnosis_data
      );
      if (diagnosis_data != null) {
        this.options = diagnosis_data.diagnosis_items;
        this.bookingDiagnosisItems.additional_items =
          diagnosis_data.additional_items;

        this.isLoaded = true;
      }
    },
  },
};
</script>

<style>
.editSummarySec a {
  cursor: pointer;
}
.editSummarySec a img {
  width: 30px;
  height: 30px;
}
.deleteCard a img {
  width: 35px;
  height: 35px;
}
.editSummarySec .addItemLink img {
  width: 25px;
  height: 25px;
}
.addItemLinkDiv {
  width: max-content;
  display: block;
  float: right;
}
</style>
