<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade modal-md show"
      id="serviceModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="closebutton d-flex justify-content-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              ></button>
            </div>
            <div class="modalCntnt pb-4">
              <h5 class="mb-0 blueText fw-bold text-center mb-3">
                Select Service
              </h5>
              <div class="row">
                <div v-for="(item, index) in services" :key="index" class="col-lg-4">
                  <div class="serviceCard form__checkbox d-flex align-items-center">
                    <input
                      type="checkbox"
                      :checked="selected === item.id"
                      @change="updateSelection(item.id)"
                      class="me-3"
                    />
                    <div>
                      <img :src="item.icon" class="mb-3" alt="" />
                      <label>{{item.title}}</label>
                    </div>
                  </div>
                </div>
              
              </div>
              <div class="bottomButtons d-flex justify-content-center mt-4">
                <button
                  class="btn buttonPrimary d-flex align-items-center me-2"
                  data-bs-dismiss="modal"
                  @click="closeModal"
                >
                  Cancel
                </button>
                <button @click=saveClicked() class="btn buttonDark d-flex align-items-center">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import {  mapGetters } from "vuex";

const toast = useToast();

export default {

  computed: {
    ...mapGetters([
      "services"
    ]),
  },

  data() {
    return {
      selected: null,
    };
  },

  methods: {
    closeModal() {
      this.$emit("event", "close");
    },
    saveClicked() {
      if (this.selected) {
        this.$emit("event", "confirm",this.selected);
      } else {
        toast.error("Please select one service type to continue.");
      }
    },
    updateSelection(value) {
      if (this.selected === value) {
        this.selected = null;
      } else {
        this.selected = value;

      }
    },
  },
};
</script>

<style>
#serviceModal .modal-body img {
  width: 50px;
  display: block;
  margin: 0 auto;
}
#serviceModal .btn {
  min-width: 100px;
  justify-content: center !important;
}
.buttonPrimary {
  background-color: #fe5000 !important;
  color: #fff !important;
  border: 1px solid #fe5000 !important;
}
.buttonDark {
  background-color: #444444 !important;
  color: #fff !important;
  border: 1px solid #092e87 !important;
}
</style>
