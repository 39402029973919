<template>
  <main v-if="unit">
    <div class="unitSelection border rounded p-4">
      <a>
        <h6
          v-if="currentIndex != '0' && !unit.id"
          @click="deleteButton()"
          class="orangeText text-end text-decoration-underline"
          style="cursor: pointer"
        >
          Delete this Unit
        </h6>
      </a>
      <div
        class="agreementPlan lightGreyBg p-2 rounded mb-3"
        v-if="units[currentIndex].is_agreement == 1"
      >
        <p class="mb-0 text-start">
          <span class="orangeText">Agreement Plan</span> -
          {{ units[currentIndex].agreement_name }}
        </p>
      </div>
      <div class="row">
        <div :class="'col-lg-4 col-md-6 col-sm-6 col-12 ' + (errorObject['unit_name'] ? 'has-error' : '') ">
          <div :id="currentIndex+'-unit_name'" class="form-group mb-3 text-start">
            <label class="mb-1 text-right">Unit Name</label>
            <input
              v-model="unit.unit_name"
              type="text"
              class="form-control"
              placeholder="Unit"
            />
          </div>
        </div>
        <div :class="'col-lg-4 col-md-6 col-sm-6 col-12 ' + (errorObject['manufacturer'] ? 'has-error' : '') ">
          <div :id="currentIndex+'-manufacturer'" class="form-group mb-3 text-start">
            <label class="mb-1">Manufacturer</label>
            <select
              v-model="selectedManufacture"
              @change="onChange('manufacturer', selectedManufacture.id)"
              class="form-select"
              aria-label="Default select example"
            >
              <option value="null" selected disabled>Select</option>
              <option
                v-for="(item, idx) in preConfig != null &&
                preConfig.manufacturers"
                :key="idx"
                :value="item"
                :selected="
                  selectedManufacture != null &&
                  item.id == selectedManufacture.id
                "
              >
                {{ item.title }}
              </option>
            </select>
          </div>
        </div>
        <div :class="'col-lg-4 col-md-6 col-sm-6 col-12 ' + (errorObject['manufacture_date'] ? 'has-error' : '')">
          <div  :id="currentIndex+'-manufacture_date'" class="form-group mb-3 text-start">
            <label class="mb-1">Manufacture Date</label>
            <date-picker
              v-model:value="unit.manufacture_date"
              input-class="disable-input"
              format="MM/DD/YYYY"
              :placeholder="'Select Date'"
              :confirm="true"
              :readonly="false"
              v-bind:clearable="false"
              :disabled-date="disabledAfter"
              valueType="format"
            >
            </date-picker>
          </div>
        </div>
        <div :class="'col-lg-4 col-md-6 col-sm-6 col-12 ' + (errorObject['system_type_id'] ? 'has-error' : '')">
          <div :id="currentIndex+'-system_type_id'" class="form-group mb-3 text-start">
            <label class="mb-1">System Type</label>
            <select
              v-model="selectedSystemType"
              @change="onChange('system_type_id', selectedSystemType.id)"
              class="form-select"
              aria-label="Default select example"
            >
              <option value="null" selected disabled>Select</option>
              <option
                v-for="(item, idx) in preConfig != null &&
                preConfig.system_types"
                :key="idx"
                :value="item"
                :selected="
                  selectedSystemType != null && item.id == selectedSystemType.id
                "
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div :class="'col-lg-4 col-md-6 col-sm-6 col-12 ' + (errorObject['product_type_id'] ? 'has-error' : '')">
          <div :id="currentIndex+'-product_type_id'" class="form-group mb-3 text-start">
            <label class="mb-1">Product Type</label>
            <select
              v-model="selectedProductType"
              @change="onChange('product_type_id', selectedProductType.id)"
              class="form-select"
              aria-label="Default select example"
              :disabled="selectedSystemType == null"
            >
              <option value="null" selected disabled>Select</option>
              <option
                v-for="(item, idx) in selectedSystemType != null &&
                selectedSystemType.product_types"
                :key="idx"
                :value="item"
                :selected="
                  selectedProductType != null &&
                  item.id == selectedProductType.id
                "
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if=" selectedProductType == null || (selectedProductType != null && selectedProductType.slug != 'cooling')" :class="'col-lg-4 col-md-6 col-sm-6 col-12 ' + (errorObject['heat_type_id'] ? 'has-error' : '')">
          <div :id="currentIndex+'-heat_type_id'" class="form-group mb-3 text-start">
            <label class="mb-1">Heat Type</label>
            <select
              v-model="selectedHeatType"
              @change="onChange('heat_type_id', selectedHeatType.id)"
              class="form-select"
              aria-label="Default select example"
              :disabled="selectedProductType == null"
            >
              <option value="null" selected disabled>Select</option>
              <option
                v-for="(item, idx) in selectedProductType != null &&
                selectedProductType.heat_types"
                :key="idx"
                :value="item"
                :selected="
                  selectedHeatType != null && item.id == selectedHeatType.id
                "
              >
                {{ item.value }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- top options -->
      <div
        class="row"
        v-if="
          configData[currentIndex] != null &&
          configData[currentIndex].questions_top
        "
      >
        <div
          v-for="(item, index) in configData[this.currentIndex].questions_top
            .data"
          :class="
            'col-lg-' +
            item.field_size +
            ' col-md-6' +
            item.field_size +
            ' col-sm-6 col-12' + 

            (errorObject[item.name] ? ' has-error' : '')
          "
          :key="index"
        >
          <div  v-if="isFieldsShow(item)">
            <div
              :id="currentIndex+'-'+item.name"
              v-if="item.type == 'dropdown'"
              class="form-group mb-3 text-start"
            >
              <label class="mb-1"> {{ item.label }}</label>
              <select
                v-model="unit[item.name]"
                class="form-select"
                aria-label="Default select example"
              >
                <option
                  :value="dropDownDefaultValue(unit[item.name])"
                  selected
                  disabled
                >
                  Select
                </option>
                <option
                  v-for="(opt, idx) in item.options"
                  :key="idx"
                  :value="opt.id"
                >
                  {{ opt.title }}
                </option>
              </select>
            </div>

            <div
              :id="currentIndex+'-'+item.name"
              v-else-if="item.type == 'text'"
              class="form-group mb-3 text-start"
            >
              <label class="mb-1"> {{ item.label }}</label>
              <input
                v-model="unit[item.name]"
                type="text"
                class="form-control"
                :placeholder="'Enter ' + item.label"
                @paste="disablePaste(item)"
                @keypress="isNumber($event, item)"
                @input="isNumber($event, item)"
              />
            </div>

            <div
              :id="currentIndex+'-'+item.name"
              v-else-if="item.type == 'rich_text'"
              class="form-group mb-3 text-start"
            >
              <label class="mb-1"> {{ item.label }}</label>
              <textarea
                v-model="unit[item.name]"
                type="text"
                class="form-control"
                :placeholder="'Enter ' + item.label"
                @paste="disablePaste(item)"
                @keypress="isNumber($event, item)"
                @input="isNumber($event, item)"
              ></textarea>
            </div>

            <div
              :id="currentIndex+'-'+item.name"
              v-else-if="item.type == 'radio'"
              class="form-group mb-3 text-start"
            >
              <h6 class="mb-3">{{ item.label }}</h6>
              <div v-if="item != null" class="radioCheck d-flex mt-3">
                <div
                  class="form-check p-0 me-3"
                  v-for="(opt, idx) in item.options"
                  :key="idx"
                >
                  <input
                    type="radio"
                    :id="item.name + currentIndex + opt.title"
                    :value="opt.id"
                    v-model="unit[item.name]"
                    :name="item.name + '-' + currentIndex"
                  />
                  <label :for="item.name + currentIndex + opt.title">{{
                    opt.title
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- middle options -->
      <div v-if="configData[currentIndex] != null">
        <div
          class="row"
          v-if="
            configData[currentIndex] != null &&
            configData[currentIndex].indoor &&
            configData[currentIndex].outdoor
          "
        >
          <!-- <div class=""> -->
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="outdoorUnit">
              <div class="outdoorHead secondarybg rounded p-2 mb-3">
                <h5 class="mb-0 whiteText">Outdoor Unit</h5>
              </div>
              <div class="serialBarcode d-flex mb-3">
                <div class="form-group text-start w-100">
                  <label class="mb-1">Serial Number</label>
                  <input
                    v-model="unit['outdoor_serial']"
                    type="text"
                    class="form-control"
                    placeholder="Enter serial"
                  />
                </div>
                <img
                  @click="openModal('outdoor_serial', 'barcode')"
                  src="../../assets/images/barcode-icon.png"
                  alt=""
                  class="mx-3"
                />
                <img
                  @click="openModal('outdoor_serial', 'qrcode')"
                  src="../../assets/images/qrcode-icon.png"
                  alt=""
                />
              </div>
              <div class="form-group text-start mb-3">
                <label class="mb-1">Model Number</label>
                <input
                  v-model="unit['outdoor_model']"
                  type="text"
                  class="form-control"
                  placeholder="Enter model"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="outdoorUnit">
              <div class="outdoorHead secondarybg rounded p-2 mb-3">
                <h5 class="mb-0 whiteText">Indoor Unit</h5>
              </div>
              <div class="serialBarcode d-flex mb-3">
                <div class="form-group text-start w-100">
                  <label class="mb-1">Serial Number</label>
                  <input
                    v-model="unit['indoor_serial']"
                    type="text"
                    class="form-control"
                    placeholder="Enter serial"
                  />
                </div>
                <img
                  @click="openModal('indoor_serial', 'barcode')"
                  src="../../assets/images/barcode-icon.png"
                  alt=""
                  class="mx-3"
                />
                <img
                  @click="openModal('indoor_serial', 'qrcode')"
                  src="../../assets/images/qrcode-icon.png"
                  alt=""
                />
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group text-start mb-3">
                    <label class="mb-1">Model Number</label>
                    <input
                      v-model="unit['indoor_model']"
                      type="text"
                      class="form-control"
                      placeholder="Enter model"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div
          v-else-if="
            configData[currentIndex] != null && configData[currentIndex].outdoor
          "
        >
          <div class="outdoorUnit">
            <div class="outdoorHead secondarybg rounded p-2 mb-3">
              <h5 class="mb-0 whiteText">Outdoor Unit</h5>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                <div class="form-group w-100">
                  <label class="mb-1">Serial Number</label>
                  <input
                    v-model="unit['outdoor_serial']"
                    type="text"
                    class="form-control"
                    placeholder="Enter serial"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                <div class="form-group mb-3">
                  <label class="mb-1">Model Number</label>
                  <input
                    v-model="unit['outdoor_model']"
                    type="text"
                    class="form-control"
                    placeholder="Enter model"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                <div class="serialBarcode d-flex mb-3">
                  <img
                    @click="openModal('outdoor_serial', 'barcode')"
                    src="../../assets/images/barcode-icon.png"
                    alt=""
                    class="mx-3"
                  />
                  <img
                    @click="openModal('outdoor_serial', 'qrcode')"
                    src="../../assets/images/qrcode-icon.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- bottom options -->
      <div
        class="row"
        v-if="
          configData[currentIndex] != null &&
          configData[currentIndex].questions_bottom
        "
      >
        <div
          v-for="(item, index) in configData[this.currentIndex].questions_bottom
            .data"
          :class="
            'col-lg-' +
            item.field_size +
            ' col-md-' +
            item.field_size +
            ' col-sm-12 col-12' + 
            (errorObject[item.name] ? ' has-error' : '')
          "
          :key="index"
        >
          <div :id="currentIndex+'-'+item.name"
            v-if="item.type == 'dropdown'"
            class="form-group mb-3 text-start"
          >
            <label class="mb-1"> {{ item.label }}</label>
            <select
              v-model="unit[item.name]"
              class="form-select"
              aria-label="Default select example"
            >
              <option
                :value="dropDownDefaultValue(unit[item.name])"
                selected
                disabled
              >
                Select
              </option>
              <option
                v-for="(opt, idx) in item.options"
                :key="idx"
                :value="opt.id"
              >
                {{ opt.title }}
              </option>
            </select>
          </div>

          <div
            :id="currentIndex+'-'+item.name"
            v-else-if="item.type == 'text'"
            class="form-group mb-3 text-start"
          >
            <label class="mb-1"> {{ item.label }}</label>
            <input
              v-model="unit[item.name]"
              type="text"
              class="form-control"
              :placeholder="'Enter ' + item.label"
              @paste="disablePaste(item)"
              @keypress="isNumber($event, item)"
              @input="isNumber($event, item)"
            />
          </div>

          <div
            :id="currentIndex+'-'+item.name"
            v-else-if="item.type == 'rich_text'"
            class="form-group mb-3 text-start"
          >
            <label class="mb-1"> {{ item.label }}</label>
            <textarea
              v-model="unit[item.name]"
              type="text"
              class="form-control"
              :placeholder="'Enter ' + item.label"
              @paste="disablePaste(item)"
              @keypress="isNumber($event, item)"
              @input="isNumber($event, item)"
            ></textarea>
          </div>

          <div
            :id="currentIndex+'-'+item.name"
            v-else-if="item.type == 'radio'"
            class="form-group mb-3 text-start"
          >
            <h6 class="mb-3">{{ item.label }}</h6>

            <div class="radioCheck d-flex mt-3">
              <div
                class="form-check p-0 me-3"
                v-for="(opt, idx) in item.options"
                :key="idx"
              >
                <input
                  type="radio"
                  :id="opt.id + item.name + currentIndex"
                  :value="opt.id"
                  v-model="unit[item.name]"
                  :name="item.name + '-' + currentIndex"
                />
                <label :for="opt.id + item.name + currentIndex">{{
                  opt.title
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>


      <button @click="resetBtn" class="btn buttonSecondary rounded" v-if="currentIndex != '0' && !unit.id" >Reset</button>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue-datepicker-next";

import _ from "lodash";
export default {
  components: {
    DatePicker,
  },

  computed: {
    ...mapGetters(["unitsList", "apiCallback", "preConfig", "config"]),

    isMobile() {
      return window.screen.width < 1024 ? false : true;
    },
  },

  props: ["carouselSlideIndex", "item", "currentIndex", "configData", "units", "errorObject"],

  data() {
    return {
      selectedManufacture: null,
      selectedSystemType: null,
      selectedProductType: null,
      selectedHeatType: null,
      isPresetSystemSet: false,
      isPresetProductSet: false,
      isPresetHeatSet: false,
      jobId: this.$route.params.id,
      unit: this.item,
      count: -1,
    };
  },

  mounted() {
   
  },
  watch: {
    selectedSystemType: {
      handler: function () {
        if (!this.isPresetSystemSet) {
          this.selectedProductType = null;
          this.selectedHeatType = null;
          this.configData[this.currentIndex] = null;
          this.resetUnitConfig()
        } else {
          this.isPresetSystemSet = false;
        }
      },
    },

    selectedProductType: {
      handler: function () {
        if (!this.isPresetProductSet) {
          this.selectedHeatType = null;
          this.configData[this.currentIndex] = null;
          this.resetUnitConfig()

          if (this.selectedProductType != null && this.selectedProductType.slug == 'cooling') {
            this.getConfig({
              system_type_id: this.selectedSystemType.id,
              product_type_id: this.selectedProductType.id,
            });
          }

        } else {
          this.isPresetProductSet = false;
        }
      },
    },

    selectedHeatType: {
      handler: function () {
        if (!this.isPresetHeatSet) {
          this.configData[this.currentIndex] = null;
          if (this.selectedHeatType != null) {
            this.resetUnitConfig()
            this.getConfig({
              system_type_id: this.selectedSystemType.id,
              product_type_id: this.selectedProductType.id,
              heat_type_id: this.selectedHeatType.id,
            });
          }
        } else {
          this.isPresetHeatSet = false;
        }
      },
    },

    carouselSlideIndex: {
      handler() {
        if (this.preConfig.manufacturers) {
          this.selectedManufacture = null;
          this.selectedSystemType = null;
          this.selectedProductType = null;
          this.selectedHeatType = null;
          this.isPresetSystemSet = false;
          this.isPresetProductSet = false;
          this.isPresetHeatSet = false;
          this.unit = this.units[this.carouselSlideIndex];
          this.setData();
        }

      },
      deep: true,
      immediate: true
    },


    config: function () {
      this.configData[this.currentIndex] = this.config;
    },

    preConfig: function () {
      this.unit = this.units[this.currentIndex];
      this.setData();
    },
  },

  methods: {
    ...mapActions(["getPreConfig", "getConfig"]),

    onChange(key, value) {
      if ( key == 'product_type_id'){
        this.unit['is_cooling_only'] = this.selectedProductType.slug == 'cooling' ? 1 : 0
      }
      this.unit[key] = value;
    },
    isFieldsShow(data) {
      if (
        data.name == "new_capacity" &&
        this.unit.is_system_sized_properly != 0
      ) {
        return false;
      } else {
        return true;
      }
    },

    setData() {
      let self = this;

      this.selectedManufacture =
        this.preConfig.manufacturers.filter(function (item) {
          return (
            self.unit["manufacturer"] != null &&
            item.id == self.unit["manufacturer"]
          );
        })[0] ?? null;

      this.isPresetSystemSet = true;
      this.selectedSystemType =
        this.preConfig.system_types.filter(function (item) {
          return (
            self.unit["system_type_id"] != null &&
            item.id == self.unit["system_type_id"]
          );
        })[0] ?? null;

      if (this.selectedSystemType != null) {
        this.isPresetProductSet = true;
        this.selectedProductType =
          this.selectedSystemType.product_types.filter(function (item) {
            return (
              self.unit["product_type_id"] != null &&
              item.id == self.unit["product_type_id"]
            );
          })[0] ?? null;
      } else {
        this.selectedProductType = null;
      }

      if (this.selectedProductType != null && this.selectedProductType.slug != 'cooling') {
        this.isPresetHeatSet = true;
        this.selectedHeatType =
          this.selectedProductType.heat_types.filter(function (item) {
            return (
              self.unit["heat_type_id"] != null &&
              item.id == self.unit["heat_type_id"]
            );
          })[0] ?? null;
      } else {
        this.selectedHeatType = null;
      }

      if (this.selectedProductType != null && this.selectedProductType.slug == 'cooling') {
        this.getConfig({
          system_type_id: this.selectedSystemType.id,
          product_type_id: this.selectedProductType.id,
        });
      } else if (this.selectedHeatType != null) {
        this.getConfig({
          system_type_id: this.selectedSystemType.id,
          product_type_id: this.selectedProductType.id,
          heat_type_id: this.selectedHeatType.id,
        });
      }

    },

    dropDownDefaultValue(data) {
      if (data == undefined) {
        return data;
      } else {
        return null;
      }
    },

    disabledAfter: function (date) {
      const cdate = new Date();
      cdate.setHours(0, 0, 0, 0);
      return date > cdate;
    },

    saveMoreUnit() {},

    jobDetailClicked() {
      this.$router.go(-1);
    },

    disablePaste(item, event) {
      if (item.validation == "float") event.preventDefault();
    },

    isNumber: function (evt, item) {
      if (item.validation == "float") {
        const inputElement = evt.target;
        let value = inputElement.value;
        
        if (evt.type === 'input') {
          value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          const [integer, decimal] = value.split('.');
          if (integer.length > 4) value = integer.slice(0, 4);
          if (decimal) {
            if (integer === "") {
              value = "";
            } else {
              value = `${integer}.${decimal.slice(0, 2)}`;
            }
          }
          if (value.startsWith('.') || value === '') {
            value = '';
          }
          inputElement.value = value;
          inputElement.dispatchEvent(new Event('input', { bubbles: true }));
        } else if (evt.type === 'keypress') {
          const charCode = evt.which || evt.keyCode;
          console.log("charCode", charCode);
          
          if (charCode === 8 || charCode === 46 || charCode === 9 || (charCode >= 48 && charCode <= 57)) {
            if (charCode === 46 && value.indexOf('.') !== -1) {
              evt.preventDefault();
              return false;
            } else {
              // Remove invalid characters and multiple decimal points
              value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
              const [integer, decimal] = value.split('.');

              // Restrict length
              if (integer.length > 3) value = integer.slice(0, 3);
              if (decimal) value = value.split('.')[0] + '.' + decimal.slice(0, 1);

              inputElement.value = value;
              return true;
            }

          }
          evt.preventDefault();
          return false;
        }

      }
    },

    resetBtn(){
        this.selectedManufacture = null;
        this.selectedSystemType = null;
        this.selectedProductType = null;
        this.selectedHeatType = null;

        for (let k in this.unit){
          this.unit[k] = null
        }
    },


    resetUnitConfig() {
      let config = this.config
      for (var key in config) {
        if (config.hasOwnProperty(key)) {
          for (var indx in config[key].data) {
            let dataItems = config[key].data[indx];
            let dataKey = dataItems.name;
            this.units[this.currentIndex][dataItems.name] = null
          }
        }
      }
    },

    deleteButton() {
      this.$emit("event", "delete", this.currentIndex);
    },

    openModal(data, type) {
      this.$emit("openModalQR", data, type);
    },
  },
};
</script>

<style>
.carousel__slide {
  display: table !important;
}

.unitSelection {
  width: 85% !important;
  margin: 0 auto;
}

.serialBarcode img {
  width: 60px;
  height: 60px;
}

.has-error input, .has-error select {
  border-color: red;
}
</style>
