// src/helpers/fingerprint.js
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export async function getBrowserFingerprint() {
  // Initialize an agent at application startup.
  const fp = await FingerprintJS.load();
  // Get the visitor identifier when you need it.
  const result = await fp.get();
  // The fingerprint is in result.visitorId.
  return result.visitorId;
}