<template>
  <main class="outdoorEquipPg">
    <div class="container">
      <div class="heading d-flex align-items-center justify-content-between">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          <b>Outdoor Equip. </b> - Job ID <span class="orangeText">8448</span>
        </h4>
        <a href="#" class="text-decoration-none"> <span class="me-2 blackText">Notes</span> <img src="../../assets/images/notes-icon.png" alt="notes-icon"> </a>
      </div>
      <div class="breadcrumbs border rounded p-2 mb-4">
        <ul class="d-flex list-inline mb-0">
          <li><a  class="greyText">My Account</a></li>
          <li>
            <a  class="greyText"
              >Service & Repair History</a
            >
          </li>
          <li>
            <a :href="'/job/detail/'+jobId" class="greyText">Job Detail</a>
          </li>
          <li class="blueText">Outdoor Equip. - Job ID 8448</li>
        </ul>
      </div>
      <div class="outdorrEquipSec">
        <div
          class="outdorrEquipHead d-flex align-items-center justify-content-between my-4"
        >
          <h5 class="blueText mb-0">Split System</h5>
          <ul class="list-inline mb-0 d-flex">
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/green-icon.png"
                class="me-2"
                alt="green-icon"
              />
              <p class="mb-0">Operating as per manufacturer's specification</p>
            </li>
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/duck-head-icon.png"
                class="me-2"
                alt="yellow-icon"
              />
              <p class="mb-0">
                Not operating as per manufacturer's specification
              </p>
            </li>
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/electric-head-icon.png"
                class="me-2"
                alt="red-icon"
              />
              <p class="mb-0">Non Operation / Critical</p>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="outdoorUnit">
              <h5 class="primaryBg whiteText p-2 rounded">Outdoor Unit</h5>
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Accumulator</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="accumulatorYes"
                          type="radio"
                          class="form__radio-input"
                          name="accumulator"
                          checked
                        />
                        <label
                          for="accumulatorYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="accumulatorPending"
                          type="radio"
                          class="form__radio-input"
                          name="accumulator"
                        />
                        <label
                          for="accumulatorPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="accumulatorNo"
                          type="radio"
                          class="form__radio-input"
                          name="accumulator"
                        />
                        <label
                          for="accumulatorNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Compressor</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="compressorYes"
                          type="radio"
                          class="form__radio-input"
                          name="compressor"
                          checked
                        />
                        <label
                          for="compressorYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="compressorPending"
                          type="radio"
                          class="form__radio-input"
                          name="compressor"
                        />
                        <label
                          for="compressorPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="compressorNo"
                          type="radio"
                          class="form__radio-input"
                          name="compressor"
                        />
                        <label
                          for="compressorNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Compressor Capacitor</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="compressorCapYes"
                          type="radio"
                          class="form__radio-input"
                          name="compressorCap"
                          checked
                        />
                        <label
                          for="compressorCapYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="compressorCapPending"
                          type="radio"
                          class="form__radio-input"
                          name="compressorCap"
                        />
                        <label
                          for="compressorCapPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="compressorCapNo"
                          type="radio"
                          class="form__radio-input"
                          name="compressorCap"
                        />
                        <label
                          for="compressorCapNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Condensing Coil</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="condensingYes"
                          type="radio"
                          class="form__radio-input"
                          name="condensing"
                          checked
                        />
                        <label
                          for="condensingYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="condensingPending"
                          type="radio"
                          class="form__radio-input"
                          name="condensing"
                        />
                        <label
                          for="condensingPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="condensingNo"
                          type="radio"
                          class="form__radio-input"
                          name="condensing"
                        />
                        <label
                          for="condensingNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Contactor</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="contractorYes"
                          type="radio"
                          class="form__radio-input"
                          name="contractor"
                          checked
                        />
                        <label
                          for="contractorYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="contractorPending"
                          type="radio"
                          class="form__radio-input"
                          name="contractor"
                        />
                        <label
                          for="contractorPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="contractorNo"
                          type="radio"
                          class="form__radio-input"
                          name="contractor"
                        />
                        <label
                          for="contractorNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Control Board</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="controlBoardYes"
                          type="radio"
                          class="form__radio-input"
                          name="controlBoard"
                          checked
                        />
                        <label
                          for="controlBoardYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="controlBoardPending"
                          type="radio"
                          class="form__radio-input"
                          name="controlBoard"
                        />
                        <label
                          for="controlBoardPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="controlBoardNo"
                          type="radio"
                          class="form__radio-input"
                          name="controlBoard"
                        />
                        <label
                          for="controlBoardNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Debris</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="debrisYes"
                          type="radio"
                          class="form__radio-input"
                          name="debris"
                          checked
                        />
                        <label
                          for="debrisYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="debrisPending"
                          type="radio"
                          class="form__radio-input"
                          name="debris"
                        />
                        <label
                          for="debrisPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="debrisNo"
                          type="radio"
                          class="form__radio-input"
                          name="debris"
                        />
                        <label
                          for="debrisNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Disconnect</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="disconnectYes"
                          type="radio"
                          class="form__radio-input"
                          name="disconnect"
                          checked
                        />
                        <label
                          for="disconnectYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="disconnectPending"
                          type="radio"
                          class="form__radio-input"
                          name="disconnect"
                        />
                        <label
                          for="disconnectPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="disconnectNo"
                          type="radio"
                          class="form__radio-input"
                          name="disconnect"
                        />
                        <label
                          for="disconnectNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Electrical Connections</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="eConnectionYes"
                          type="radio"
                          class="form__radio-input"
                          name="eConnection"
                          checked
                        />
                        <label
                          for="eConnectionYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="eConnectionPending"
                          type="radio"
                          class="form__radio-input"
                          name="eConnection"
                        />
                        <label
                          for="eConnectionPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="eConnectionNo"
                          type="radio"
                          class="form__radio-input"
                          name="eConnection"
                        />
                        <label
                          for="eConnectionNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">High Side Pressure</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="hsPressureYes"
                          type="radio"
                          class="form__radio-input"
                          name="hsPressure"
                          checked
                        />
                        <label
                          for="hsPressureYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="hsPressurePending"
                          type="radio"
                          class="form__radio-input"
                          name="hsPressure"
                        />
                        <label
                          for="hsPressurePending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="hsPressureNo"
                          type="radio"
                          class="form__radio-input"
                          name="hsPressure"
                        />
                        <label
                          for="hsPressureNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="indoorUnit">
              <h5 class="primaryBg whiteText p-2 rounded">Indoor Unit</h5>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Air Cooler</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="airCoolerYes"
                          type="radio"
                          class="form__radio-input"
                          name="airCooler"
                          checked
                        />
                        <label
                          for="airCoolerYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="airCoolerPending"
                          type="radio"
                          class="form__radio-input"
                          name="airCooler"
                        />
                        <label
                          for="airCoolerPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="airCoolerNo"
                          type="radio"
                          class="form__radio-input"
                          name="airCooler"
                        />
                        <label
                          for="airCoolerNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">UV Light</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="uvLightYes"
                          type="radio"
                          class="form__radio-input"
                          name="uvLight"
                          checked
                        />
                        <label
                          for="uvLightYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="uvLightPending"
                          type="radio"
                          class="form__radio-input"
                          name="uvLight"
                        />
                        <label
                          for="uvLightPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="uvLightNo"
                          type="radio"
                          class="form__radio-input"
                          name="uvLight"
                        />
                        <label
                          for="uvLightNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Burner</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="burnerYes"
                          type="radio"
                          class="form__radio-input"
                          name="burner"
                          checked
                        />
                        <label
                          for="burnerYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="burnerPending"
                          type="radio"
                          class="form__radio-input"
                          name="burner"
                        />
                        <label
                          for="burnerPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="burnerNo"
                          type="radio"
                          class="form__radio-input"
                          name="burner"
                        />
                        <label
                          for="burnerNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Condensate Pump</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="condensatePumpYes"
                          type="radio"
                          class="form__radio-input"
                          name="condensatePump"
                          checked
                        />
                        <label
                          for="condensatePumpYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="condensatePumpPending"
                          type="radio"
                          class="form__radio-input"
                          name="condensatePump"
                        />
                        <label
                          for="condensatePumpPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="condensatePumpNo"
                          type="radio"
                          class="form__radio-input"
                          name="condensatePump"
                        />
                        <label
                          for="condensatePumpNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Control Board</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="cbYes"
                          type="radio"
                          class="form__radio-input"
                          name="cb"
                          checked
                        />
                        <label for="cbYes" class="form__radio-label greenRadio">
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="cbPending"
                          type="radio"
                          class="form__radio-input"
                          name="cb"
                        />
                        <label
                          for="cbPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="cbNo"
                          type="radio"
                          class="form__radio-input"
                          name="cb"
                        />
                        <label for="cbNo" class="form__radio-label redRadio">
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Disconnect</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="discnnctYes"
                          type="radio"
                          class="form__radio-input"
                          name="discnnct"
                          checked
                        />
                        <label
                          for="discnnctYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="discnnctPending"
                          type="radio"
                          class="form__radio-input"
                          name="discnnct"
                        />
                        <label
                          for="discnnctPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="discnnctNo"
                          type="radio"
                          class="form__radio-input"
                          name="discnnct"
                        />
                        <label
                          for="discnnctNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Drain Pan/Line</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="drainPanYes"
                          type="radio"
                          class="form__radio-input"
                          name="drainPan"
                          checked
                        />
                        <label
                          for="drainPanYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="drainPanPending"
                          type="radio"
                          class="form__radio-input"
                          name="drainPan"
                        />
                        <label
                          for="drainPanPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="drainPanNo"
                          type="radio"
                          class="form__radio-input"
                          name="drainPan"
                        />
                        <label
                          for="drainPanNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Duct Work</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="ductWorkYes"
                          type="radio"
                          class="form__radio-input"
                          name="ductWork"
                          checked
                        />
                        <label
                          for="ductWorkYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="ductWorkPending"
                          type="radio"
                          class="form__radio-input"
                          name="ductWork"
                        />
                        <label
                          for="ductWorkPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="ductWorkNo"
                          type="radio"
                          class="form__radio-input"
                          name="ductWork"
                        />
                        <label
                          for="ductWorkNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Electrical Connections</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="eConYes"
                          type="radio"
                          class="form__radio-input"
                          name="eCon"
                          checked
                        />
                        <label
                          for="eConYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="eConPending"
                          type="radio"
                          class="form__radio-input"
                          name="eCon"
                        />
                        <label
                          for="eConPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="eConNo"
                          type="radio"
                          class="form__radio-input"
                          name="eCon"
                        />
                        <label for="eConNo" class="form__radio-label redRadio">
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div class="form-group mb-3 lightGreyBg px-2 py-3 rounded">
                    <h6 class="mb-2">Evaporator Coil</h6>
                    <div class="radioCheck customRadiocheck d-flex">
                      <div class="form__radio-group">
                        <input
                          id="evaporatprCoilYes"
                          type="radio"
                          class="form__radio-input"
                          name="evaporatprCoil"
                          checked
                        />
                        <label
                          for="evaporatprCoilYes"
                          class="form__radio-label greenRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="evaporatprCoilPending"
                          type="radio"
                          class="form__radio-input"
                          name="evaporatprCoil"
                        />
                        <label
                          for="evaporatprCoilPending"
                          class="form__radio-label yellowRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input
                          id="evaporatprCoilNo"
                          type="radio"
                          class="form__radio-input"
                          name="evaporatprCoil"
                        />
                        <label
                          for="evaporatprCoilNo"
                          class="form__radio-label redRadio"
                        >
                          <span class="form__radio-button"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomButtons d-flex justify-content-center mt-5">
        <button class="btn buttonDark d-flex align-items-center rounded me-2 text-uppercase">
          Back
        </button>
        <button class="btn buttonPrimary d-flex align-items-center rounded text-uppercase">
          Next
        </button>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style>
.heading a img{
    width: 40px;
}
.outdorrEquipHead img {
  width: 20px;
}
.outdorrEquipHead p {
  font-size: 15px;
}
.outdorrEquipSec .form-group h6 {
  font-size: 14px;
}
</style>
