<template>
  <div v-if="item">
    <ul class="list-inline mb-0 d-flex flex-wrap">
      <li
        class="d-flex align-items-center justify-content-between me-2"
        v-for="(opt, index) in item.parts"
        :key="index"
      >
        <p class="mb-0 greyText me-3">{{ opt.part_name }}</p>
        <div v-if="!bookingResponse.is_flat_price_model"> 
          <a @click="editItem(opt, index)"
            ><img
              src="../../assets/images/edit-pen-icon.png"
              alt="edit-icon"
              class="me-2"
          /></a>
          <a @click="removeItem(opt)"
            ><img
              src="../../assets/images/cross-orange-icon.png"
              alt="delete-icon"
          /></a>
        </div>
      </li>
    </ul>

    <!-- Modal -->
    <div
      class="modal fade"
      id="singleSelectedPart"
      ref="singleSelectedPart"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-body pt-2 px-0 pb-4">
            <!-- <div class="closebutton d-flex justify-content-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideModal"
              ></button>
            </div> -->
            <div class="modalCntnt">
              <nest_modal_part_card
                :item="selectedPart"
                :index="selectedPartIndex"
                :selectedItem="item"
              />
              <div class="bottomButtons d-flex justify-content-center mt-3">
                <button
                  class="modalBtn buttonPrimary d-flex align-items-center text-uppercase me-2"
                  data-bs-dismiss="modal"
                  @click="hideModal"
                >
                  Cancel
                </button>
                <button
                  class="modalBtn buttonSecondary d-flex align-items-center text-uppercase"
                  @click="saveChanges"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {  mapGetters } from "vuex";

import nest_modal_part_card from "../../components/nest_components/nest_modal_parts_card.vue";
import { Modal } from "bootstrap";
export default {
  components: {
    nest_modal_part_card,
  },
  props: ["item"],
  computed: {
    ...mapGetters([
      "bookingResponse"
    ]),
  
  },
  data() {
    return {
      modal: null,
      selectedPart: null,
      selectedPartIndex: 0,
    };
  },

  mounted() {
    const modalElement = document.getElementById("singleSelectedPart");
    modalElement.addEventListener("show.bs.modal", this.addOverflowHidden);
    modalElement.addEventListener("hidden.bs.modal", this.removeOverflowHidden);
  },

  beforeUnmount() {
    const modalElement = document.getElementById("singleSelectedPart");
    modalElement.removeEventListener("show.bs.modal", this.addOverflowHidden);
    modalElement.removeEventListener(
      "hidden.bs.modal",
      this.removeOverflowHidden
    );
  },

  methods: {
    addOverflowHidden() {
      document.documentElement.classList.add("overflow-hidden");
    },

    removeOverflowHidden() {
      document.documentElement.classList.remove("overflow-hidden");
    },
    removeItem(data) {
      var i = this.item;
      i.parts = i.parts.filter(function (item) {
        return item !== data;
      });
    },

    editItem(data, indx) {
      this.selectedPart = JSON.parse(JSON.stringify(data));
      this.selectedPartIndex = indx;
      this.modal = new Modal(this.$refs.singleSelectedPart);
      this.modal.show();
    },

    saveChanges() {
      // Add your save logic here
      this.item.parts[this.selectedPartIndex] = this.selectedPart;
      this.hideModal();
    },

    hideModal() {
      if (this.modal) {
        this.modal.hide();
        this.removeBackdrop();
      }
    },

    removeBackdrop() {
      document.querySelectorAll(".modal-backdrop").forEach((backdrop) => {
        backdrop.remove();
      });
    },
  },
};
</script>

<style>
div#singleSelectedPart .partsCard {
  box-shadow: none;
  margin: 0 0.5rem !important;
}
</style>
