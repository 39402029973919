<template>
  <div class="partsCard rounded" v-if="item">
    <h6 class="secondarybg whiteText p-2 rounded mb-2">
      {{ item.part_name }}
    </h6>
    <div class="partsDet p-3">
      <p class="mb-3 blackText">
        Category:
        <span class="orangeText"> {{ item.category_name }}</span>
      </p>
      <div
        class="quantity d-flex justify-content-between align-items-center mb-3"
      >
        <h6 class="mb-0 blackText">Quantity</h6>
        <div class="product-count">
          <button
            class="button-count secondarybg"
            :disabled="item.quantity == 1 || item['is_warranty'] == 1"
            @click="decrement(item)"
          >
            -
          </button>
          <input
            type="text"
            readonly
            class="number-product rounded"
            :value="item.quantity"
          />
          <button
            class="button-count primaryBg"
            :disabled="item.quantity === 10 || item['is_warranty'] == 1"
            @click="increment(item)"
          >
            +
          </button>
        </div>
      </div>
      <div
        class="pertPrice d-flex justify-content-between align-items-center mb-3"
      >
        <h6 class="mb-0 blackText">
          {{
            item.is_special
              ? "Special Part Price Per Unit:"
              : "Part Price Per Unit:"
          }}
        </h6>
        <input
          @paste="disablePaste"
          @keypress="isNumber($event)"
          @input="isNumber($event)"
          inputmode="numeric"
          type="text"
          :disabled="item['is_warranty'] == 1"
          v-model="item.cost_with_markup"
          size="4"
          class="form-control mb-0 lightGreyBg rounded orangeText"
        />
      </div>
      <div class="form-group">
        <h6 class="mb-3 blackText">Is this part being replaced under:</h6>
        <div class="form-check d-flex p-0">
          <div class="form__checkbox d-flex align-items-center me-5">
            <input type="checkbox" name="check" @change="handleClick(item)" v-model="item['is_warranty']" :id="selectedItem.diagnostic_option_id+'-'+index"/>
            <label class="blueText" :for="selectedItem.diagnostic_option_id+'-'+index">Part Warranty</label>
          </div>
        </div>
      </div>
      <!-- <a @click="removeItem(item)">
        <img
          src="../../assets/images/delete-fill-icon.png"
          alt="delete-icon"
          class="mx-auto d-table mt-3"
        />
      </a> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["item", "index", "selectedItem"],
  data() {
    return {
      quantity: 1,
    };
  },
  methods: {
    decrement(data) {
      if (data.quantity > 1) {
        data.quantity--;
      }
    },
    increment(data) {
      if (data.quantity < 10) {
        data.quantity++;
      }
    },
    disablePaste(event) {
      event.preventDefault();
    },

    isNumber(evt) {
      //validate
      const inputElement = evt.target;
      let value = inputElement.value;
      if (evt.type === 'input') {
          value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          const [integer, decimal] = value.split('.');
          if (integer.length > 4) value = integer.slice(0, 4);
          if (decimal) {
            if (integer === "") {
              value = "";
            } else {
              value = `${integer}.${decimal.slice(0, 2)}`;
            }
          }
          if (value.startsWith('.') || value === '') {
            value = '';
          }
          inputElement.value = value;
          inputElement.dispatchEvent(new Event('input', { bubbles: true }));
        } else if (evt.type === 'keypress') {
        const charCode = evt.which || evt.keyCode;
        if (charCode === 8 || charCode === 46 || charCode === 9 || (charCode >= 48 && charCode <= 57) ) {
          if (charCode === 46 && value.indexOf('.') !== -1) {
            evt.preventDefault();
            return false;
          }
          return true;
        }
        evt.preventDefault();
        return false;
      }
      // Remove invalid characters and multiple decimal points
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      const [integer, decimal] = value.split('.');
      // Restrict length
      if (integer.length > 4) value = integer.slice(0, 4);
      if (decimal) value = value.split('.')[0] + '.' + decimal.slice(0, 2);
      inputElement.value = value;
    },

    handleClick(data) {
      if (data.is_warranty){
        data.cost_with_markup = "0"
        data.quantity = "1"
      }
    },
  },
};
</script>
