<template>
  <div>
    <div class="container">
      <div class="heading">
        <h4 class="mb-0 blueText fw-bold pt-4 pb-3 border-bottom mb-5">
          Dashboard
        </h4>
      </div>
      <div class="dashboardMain">
        <nest_card />
      </div>
    </div>
  </div>
</template>

<script>
import nest_card from "../../components/nest_components/dashboard.vue";
export default {
  components: {
    nest_card,
  },
};
</script>
