<template>
  <div v-if="parts && selectedItem">
    <h6 class="primaryBg whiteText rounded p-2 mb-2" v-if="isPartHeader">
      Parts
    </h6>
    <div class="selectedModalParts">
      <div class="row">
        <div class="col-lg-6" v-for="(item, index) in parts" :key="index">
          <div class="partsCard p-2 rounded">
            <h6 class="secondarybg whiteText p-2 rounded mb-2">
              {{ item.title }}
            </h6>
            <div class="partsDet pt-3">
              <p class="mb-3 blackText">
                Category:
                <span class="orangeText"> {{ item.category_name }}</span>
              </p>
              <div
                class="quantity d-flex justify-content-between align-items-center mb-3"
              >
                <h6 class="mb-0 blackText">Quantity</h6>
                <div class="product-count">
                  <input
                    type="text"
                    readonly
                    class="number-product rounded"
                    :value="item.quantity"
                  />
                </div>
              </div>
              <div
                class="pertPrice d-flex justify-content-between align-items-center mb-3"
              >
                <h6 class="mb-0 blackText">
                  {{
                    item.is_special
                      ? "Special Part Price Per Unit:"
                      : "Part Price Per Unit:"
                  }}
                </h6>
                <span
                  size="4"
                  class="form-control mb-0 lightGreyBg rounded orangeText"
                  >{{ item.price_text }}</span
                >
              </div>
              <div class="form-group">
                <h6 class="mb-3 blackText">
                  Is this part being replaced under:
                </h6>
                <div class="form-check d-flex p-0 disableCheck">
                  <div class="form__checkbox d-flex align-items-center me-5">
                    <input
                    class="diasble_checkbox"
                      type="checkbox"
                      name="check"
                      disabled
                      v-model="item['is_warranty']"
                    />
                    <label class="blueText" for="">Part Warranty</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="partsTotalSec">
      <div class="d-flex justify-content-between align-items-center p-2 rounded lightGreyBg">
        <p class="blueText mb-0">(Parts Total: {{ selectedItem.part_total_cost_text }} <span class="orangeText fw-bolder">|</span><br/> Service Fee: {{ selectedItem.service_fee_text }})</p>
        <p class="blueText mb-0 fw-bold">Total Cost: {{ selectedItem.total_cost_text }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["parts", "isPartHeader", "selectedItem"],
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
.selectedModalParts .product-count .number-product {
  width: 70px;
  height: 35px;
  background: #f1f1f1;
}
.selectedModalParts .pertPrice .form-control {
  text-align: center;
  background: #f1f1f1;
}



.disableCheck .form__checkbox input[type="checkbox"] {
    border-color: #ccc;
    background: #EFEFEF;
}
.form__checkbox input[type="checkbox"]:checked:after,
.form__checkbox input[type="checkbox"]:checked:before{
  background: #ccc;
}

.disableCheck label{
    color: #666666;
}
.partsTotalSec p br {
  display: none;
}
</style>
