<template>
  <div class="avalPartCard p-4 mt-3">
    <div v-if="isSpecialPart" class="form-group mb-3" id="parts_datetime">
      <label class="mb-1 blackText">Special Part Availability Date</label>
      <date-picker
        input-class="disable-input"
        format="MM/DD/YYYY"
        :placeholder="'Select Date'"
        type="date"
        :use12h="true"
        :confirm="true"
        :readonly="false"
        v-bind:clearable="false"
        :default-value="new Date()"
        :disabled-date="disabledBefore"
        :disabled-time="disabledBeforeTime"
        :editable="false"
        v-model:value="storeObject['parts_datetime']"
        valueType="format"
      >
      </date-picker>
      <small class="text-danger fw-bold" v-if="storeObject.is_error_parts_datetime">
        *Special part date is required
      </small>

    </div>
    <div class="form-group mb-3">
      <div
        class="button-form-group mb-1 d-flex align-items-center justify-content-between"
      >
        <label class="mb-1">Misc. Items</label>
        <span v-if="partsTotal > 0" class="lightGreyBg orangeText"
          >Total: ${{ partsTotal }}</span
        >
      </div>
      <div class="form-select mb-2 text-start" @click="viewAdditionalItems()">
        <span
          v-if="
            bookingDiagnosisItems.additional_items &&
            bookingDiagnosisItems.additional_items.length > 0
          "
          >{{ bookingDiagnosisItems.additional_items.length }} Items</span
        >
        <span v-else>Select Misc. Items</span>
        <img src="../../assets/images/button-icon.png" alt="" />
      </div>
    </div>

    <div v-if="!bookingResponse.depp_schedule.status || (bookingResponse.depp_schedule.status && bookingResponse.depp_schedule.can_apply_recommended_discount)" class="form-group mb-3" id="recommended_discount">
      <label class="mb-1"
        >Recommended Discount %
        <span class="greyText"
          >(Discount appliable on Service fee of items not operating as per
          manufacturer specification)</span
        ></label>
    
      <input 
        type="text"
        class="form-control"
        v-model="storeObject['recommended_discount']"
        @paste="disablePaste($event)"
        @keypress="isNumber($event)"
        @input="isNumber($event)"
        disabled
      />
    </div>

  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="viewAdditionalItemModal"
    ref="viewAdditionalItemModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="modalCntnt pt-3">
            <nest_additional_table
              :additionalItems="selectedAdditionItems"
            ></nest_additional_table>
          </div>
          <div
            class="bottomButtons border-top d-flex justify-content-center pt-4 mt-4"
          >
            <button
              class="modalBtn buttonDark d-flex align-items-center me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              CLOSE
            </button>
            <button
              @click="saveButton()"
              class="modalBtn buttonPrimary d-flex align-items-center"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue-datepicker-next";
import nest_additional_table from "../../components/nest_components/nest_additional_table.vue";
import { Modal } from "bootstrap";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";

const toast = useToast();

export default {
  props: ["bookingDiagnosisItems", "options", "storeObject"],

  data() {
    return {
      selectedAdditionItems: [],
    };
  },

  components: {
    DatePicker,
    nest_additional_table,
  },

  computed: {

    ...mapGetters([
      "bookingResponse",
    ]),
    isSpecialPart() {
      let res = false;
      if (this.options) {
        outerLoop: for (let i = 0; i < this.options.length; i++) {
          let element = this.options[i];
          if (element.parts) {
            for (let j = 0; j < element.parts.length; j++) {
              let part = element.parts[j];
              if (part.is_special) {
                res = true;
                break outerLoop;
              }
            }
          }
        }
      }
      this.storeObject["isSpecial"] = res;
      return res;
    },

    partsTotal() {
      let res = 0;
      if (
        this.bookingDiagnosisItems &&
        this.bookingDiagnosisItems.additional_items
      ) {
        this.bookingDiagnosisItems.additional_items.forEach((element) => {
          if (element.price.length < 0) {
            element.price = 0;
          }
          res = Number(res) + Number(element.price);
        });
      }
      return res;
    },
  },

  watch: {
    "storeObject.parts_datetime": {
      handler() {
        if ((this.storeObject["parts_datetime"] + "").length > 0) {
          this.storeObject["is_error_parts_datetime"] = false;
        }
      },
      deep: true,
    },
  },

  mounted() {
    const modalElement = document.getElementById('viewAdditionalItemModal');
    modalElement.addEventListener('show.bs.modal', this.addOverflowHidden);
    modalElement.addEventListener('hidden.bs.modal', this.removeOverflowHidden);
  },

  beforeUnmount() {
    const modalElement = document.getElementById('viewAdditionalItemModal');
    modalElement.removeEventListener('show.bs.modal', this.addOverflowHidden);
    modalElement.removeEventListener('hidden.bs.modal', this.removeOverflowHidden);
  },

  methods: {
    addOverflowHidden() {
      document.documentElement.classList.add('overflow-hidden');
    },
    removeOverflowHidden() {
      document.documentElement.classList.remove('overflow-hidden');
    },
    disabledBefore: function (date) {
      const cdate = new Date();
      cdate.setHours(0, 0, 0, 0);
      return date < cdate;
    },

    disabledBeforeTime: function (date) {
      const cdate = new Date();
      return date < cdate;
    },

    viewAdditionalItems() {
      this.selectedAdditionItems =
        JSON.parse(
          JSON.stringify(this.bookingDiagnosisItems.additional_items)
        ) ?? [];
      this.modal = new Modal(this.$refs.viewAdditionalItemModal);
      this.modal.show();
    },

    saveButton() {
      let res = true;
      let msg = "";
      this.selectedAdditionItems.forEach((element) => {
        if (element.title.length <= 0) {
          msg = "Title field is required.";
          res = false;
        } else if (element.price.length <= 0) {
          msg = "Price field is required.";
          res = false;
        }
      });

      if (res) {
        this.bookingDiagnosisItems.additional_items =
          this.selectedAdditionItems;
        this.modal.hide();
      } else {
        toast.error(msg);
      }
    },

    disablePaste(event) {
      event.preventDefault();
    },

    isNumber(evt) {
      //validate
      const inputElement = evt.target;
      let value = inputElement.value;
      if (evt.type === 'input') {
          value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          const [integer, decimal] = value.split('.');
          if (integer.length > 4) value = integer.slice(0, 4);
          if (decimal) {
            if (integer === "") {
              value = "";
            } else {
              value = `${integer}.${decimal.slice(0, 2)}`;
            }
          }
          if (value.startsWith('.') || value === '') {
            value = '';
          }
          inputElement.value = value;
          inputElement.dispatchEvent(new Event('input', { bubbles: true }));
        } else if (evt.type === 'keypress') {
        const charCode = evt.which || evt.keyCode;
        if (charCode === 8 || charCode === 46 || charCode === 9 || (charCode >= 48 && charCode <= 57) ) {
          if (charCode === 46 && value.indexOf('.') !== -1) {
            evt.preventDefault();
            return false;
          }
          return true;
        }
        evt.preventDefault();
        return false;
      }
      // Remove invalid characters and multiple decimal points
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      const [integer, decimal] = value.split('.');
      // Restrict length
      if (integer.length > 4) value = integer.slice(0, 4);
      if (decimal) value = value.split('.')[0] + '.' + decimal.slice(0, 2);
      inputElement.value = value;
    },
  },
};
</script>

<style>
.avalPartCard label span {
  font-style: italic;
  font-size: 14px;
}
.avalPartCard .form-select{
  position: relative;
}
.avalPartCard .form-select img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}
</style>
