<template>
  <div>
    <div class="modal-content notesModal-wrap">
      <div class="modal-header d-flex justify-content-between">
        <!-- <span>&nbsp;</span> -->
        <h5 class="modal-title" id="exampleModalLabel">Notes</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src="@/assets/images/cross-btn.png" />
        </button>
      </div>
      <div class="modal-body">
        <div class="modalChat-box">
          <div class="chatBox" v-for="(item, index) in jobNotes" :key="index">
            <div class="chatAction">
              <div>{{ convertNotesTimsStampToDate(item.created_at) }}:</div>
              <span v-if="item.is_deletable == 1" @click="deleteNoteBtn(item)"
                ><i class="fa fa-trash"></i
              ></span>
            </div>
            <div class="chatMsg">
              <p>{{ item.notes }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <div class="writeMsg">
          <textarea
            v-model="notes"
            placeholder="Enter notes here..."
          ></textarea>
          <button
            v-if="selectedNotes != null"
            :disabled="txtDisable"
            @click="updateNotesBtn"
          >
            Update Note
          </button>
          <button v-else :disabled="txtDisable" @click="addNotesBtn">
            Add Note
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {},

  data() {
    return {
      modal: null,
      selectedNotes: null,
      notes: "",
      jobNotes: [],
      txtDisable: true,
    };
  },
  computed: {
    ...mapGetters(["quoteDetail", "notesData"]),
  },

  watch: {
    notesData: function () {
      this.selectedNotes = null;
      this.notes = "";
      this.jobNotes = this.notesData ?? [];
    },

    notes: function () {
      if (!this.isEmpty(this.notes)) {
        this.txtDisable = false;
      } else {
        this.txtDisable = true;
      }
    },
  },

  mounted() {},

  methods: {
    ...mapActions(["updateNote", "addNote", "deleteNote"]),

    convertNotesTimsStampToDate(val) {
      return moment.unix(val).format("hh:mm A - MM/DD/YYYY");
    },
    editNoteBtn(data) {
      this.selectedNotes = data;
      this.notes = this.selectedNotes.notes;
    },
    deleteNoteBtn(data) {
      this.$dialog.show({
        title: "Delete Note",
        text: "Are you sure you want to delete this note?",
        // image: "pop-req-img.png",
        // we are passing callback method for our confirm button
        onConfirm: () => {
          this.deleteNote({
            id: data.id,
          });
        },
      });
    },
    addNotesBtn() {
      if (this.isValid()) {
        this.addNote({
          quote_id: this.quoteDetail.id,
          notes: this.notes,
        });
      }
    },
    updateNotesBtn() {
      if (this.isValid()) {
        this.updateNote({
          id: this.selectedNotes.id,
          notes: this.notes,
        });
      }
    },
    isValid() {
      let res = true;
      if (this.isEmpty(this.notes)) {
        toast.error("Please enter note");
        res = false;
      }
      return res;
    },
    isEmpty(value) {
      return (
        value == null ||
        value.trim().length === 0 ||
        (value.constructor === Object && Object.keys(value).length === 0)
      );
    },
  },
};
</script>

<style>
.modalChat-box {
  background: #d9d9d9;
  padding: 15px 0;
  border-radius: 10px;
  min-height: 470px;
  overflow: auto;
  max-height: 470px;
}

/* width */
.modalChat-box::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
.modalChat-box::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.chatBox {
  border-bottom: 1px solid #3c3c3c;
  margin-bottom: 15px;
  padding: 0 10px 10px 10px;
}

.chatAction {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.chatAction div {
  color: #092e87;
}

.chatAction span {
  color: #ff5b01;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
}

.chatAction span:hover {
  color: #092e87;
}

.chatMsg p {
  font-size: 14px;
  color: #3c3c3c;
  margin: 0;
}

.notesModal-wrap .modal-header {
  background: #092e87;
  color: #fff;
  padding: 10px 20px;
}

.notesModal-wrap .modal-header .btn-close {
  opacity: 1;
  text-indent: 000;
  background: none;
  margin: 0;
  height: auto;
  padding: 0;
  box-shadow: none;
}

.notesModal-wrap .modal-header .btn-close img {
  height: 22px;
  width: 22px;
}

.notesModal-wrap .modal-header .edit-icon {
  cursor: pointer;
  height: 17px;
  width: 17px;
}

.notesModal-wrap .modal-footer {
  border-top: 1px solid #3c3c3c;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}

.notesModal-wrap .modal-footer .writeMsg {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.notesModal-wrap .modal-footer .writeMsg textarea {
  width: 100%;
  border-radius: 10px;
  padding: 5px;
  font-size: 14px;
  margin-right: 10px;
  height: 70px;
}

.notesModal-wrap .modal-footer .writeMsg button {
  color: #fff;
  background-color: #ff5b01;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  line-height: normal;
}

.notesModal-wrap .modal-footer .writeMsg button:hover {
  background: #092e87;
}

.notesModal-wrap .modal-footer .writeMsg button:disabled {
  background: #ccc;
}
</style>
