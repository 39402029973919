<template>
  <div v-if="selectedItem">
    <h6 class="primaryBg whiteText rounded p-2 mb-2">Parts Selected</h6>
    <div class="selectedModalParts">
      <div class="row">
        <div
          class="col-lg-6 col-md-6 col-sm-6 col-12"
          v-for="(item, index) in selectedItem.parts"
          :key="index"
        >
          <div class="partsCard p-2 rounded">
            <h6 class="secondarybg whiteText p-2 rounded mb-2">
              {{ item.part_name }}
            </h6>
            <div class="partsDet pt-3">
              <p class="mb-3 blackText">
                Category:
                <span v-if="!bookingResponse.is_flat_price_model" class="orangeText"> {{ item.category_name }}</span>
                <span v-else class="orangeText"> Component</span>

              </p>
              <div
                class="quantity d-flex justify-content-between align-items-center mb-3"
              >
                <h6 class="mb-0 blackText">Quantity</h6>
                <div class="product-count">
                  <button
                    class="button-count secondarybg"
                    :disabled="item.quantity == 1 || item['is_warranty'] == 1 || bookingResponse.is_flat_price_model"
                    @click="decrement(item)"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    readonly
                    class="number-product rounded"
                    :value="item.quantity"
                  />
                  <button
                    class="button-count primaryBg"
                    :disabled="item.quantity === 10 || item['is_warranty'] == 1 || bookingResponse.is_flat_price_model"
                    @click="increment(item)"
                  >
                    +
                  </button>
                </div>
              </div>
              <div
                class="pertPrice d-flex justify-content-between align-items-center mb-3"
              >
                <h6 class="mb-0 blackText">
                  {{
                    item.is_special
                      ? "Special Part Price Per Unit:"
                      : "Part Price Per Unit:"
                  }}
                </h6>
                <input
                  @paste="disablePaste"
                  @keypress="isNumber($event)"
                  @input="isNumber($event)"
                  inputmode="numeric"
                  type="text"
                  :disabled="item['is_warranty'] == 1"
                  v-model="item.cost_with_markup"
                  size="4"
                  class="form-control mb-0 lightGreyBg rounded orangeText text-center"
                />
              </div>
              <div class="form-group">
                <h6 class="mb-3 blackText">
                  Is this part being replaced under:
                </h6>
                <div class="form-check d-flex p-0">
                  <div class="form__checkbox d-flex align-items-center me-5">
                    <input
                      type="checkbox"
                      name="check"
                      v-model="item['is_warranty']"
                      @change="handleClick(item)"
                      :id="selectedItem.diagnostic_option_id + '-' + index"
                      :disabled = "bookingResponse.is_flat_price_model"
                    />
                    <label
                      class="blueText"
                      :for="selectedItem.diagnostic_option_id + '-' + index"
                      :disabled = "bookingResponse.is_flat_price_model"
                      >Part Warranty</label
                    >
                  </div>
                </div>
                <a v-if="!bookingResponse.is_flat_price_model" class="removeCard" @click="removeItem(item)">
                  <img
                    src="../../assets/images/delete-fill-icon.png"
                    alt="delete-icon"
                    class="mx-auto d-table mt-3"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomButtons d-flex justify-content-center mt-5">
      <button
        class="modalBtn buttonPrimary d-flex align-items-center text-uppercase me-2"
        @click="closeButtonClicked()"
      >
        Cancel
      </button>
      <button
        @click="saveButtonClicked()"
        :class="'modalBtn buttonSecondary d-flex align-items-center text-uppercase ' + (isAnyItemSlected ? 'disabled' : '') "
        :disabled="isAnyItemSlected"
      >
        Save
      </button>
    </div>
  </div>
</template>
<script>
import {  mapGetters } from "vuex";

import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  props: ["item"],
  data() {
    return {
      selectedItem: null,
    };
  },

  computed: {
    ...mapGetters([
      "bookingResponse"
    ]),
    isAnyItemSlected() {
      return (this.selectedItem.parts && this.selectedItem.parts.length > 0 )? false : true;
    }
  },

  watch: {
    item: function () {
      this.selectedItem = this.item;
    },
  },

  mounted() {},

  methods: {
    saveButtonClicked() {
      let res = true;
      let msg = "";
      this.selectedItem.parts.forEach((element) => {
        if (!element["is_warranty"] && element.cost_with_markup.length <= 0) {
          msg = "Price field is required.";
          res = false;
        }
      });

      if (res) {
        this.$emit("event", "save");
      } else {
        toast.error(msg);
      }
    },

    closeButtonClicked() {
      this.$emit("event", "close");
    },

    decrement(data) {
      if (data.quantity > 1) {
        data.quantity--;
      }
    },
    increment(data) {
      if (data.quantity < 10) {
        data.quantity++;
      }
    },

    handleClick(data) {
      if (data.is_warranty) {
        data.cost_with_markup = "0";
        data.quantity = "1";
      }
    },

    removeItem(data) {
      this.selectedItem.parts = this.selectedItem.parts.filter(function (item) {
        return item !== data;
      });
    },

    disablePaste(event) {
      event.preventDefault();
    },

    isNumber(evt) {
      //validate
      const inputElement = evt.target;
      let value = inputElement.value;
      if (evt.type === 'input') {
          value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          const [integer, decimal] = value.split('.');
          if (integer.length > 4) value = integer.slice(0, 4);
          if (decimal) {
            if (integer === "") {
              value = "";
            } else {
              value = `${integer}.${decimal.slice(0, 2)}`;
            }
          }
          if (value.startsWith('.') || value === '') {
            value = '';
          }
          inputElement.value = value;
          inputElement.dispatchEvent(new Event('input', { bubbles: true }));
        } else if (evt.type === 'keypress') {
        const charCode = evt.which || evt.keyCode;
        if (charCode === 8 || charCode === 46 || charCode === 9 || (charCode >= 48 && charCode <= 57) ) {
          if (charCode === 46 && value.indexOf('.') !== -1) {
            evt.preventDefault();
            return false;
          }
          return true;
        }
        evt.preventDefault();
        return false;
      }
      // Remove invalid characters and multiple decimal points
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      const [integer, decimal] = value.split('.');
      // Restrict length
      if (integer.length > 4) value = integer.slice(0, 4);
      if (decimal) value = value.split('.')[0] + '.' + decimal.slice(0, 2);
      inputElement.value = value;
    },

  },
};
</script>

<style>
.removeCard img {
  width: 30px;
  height: 30px;
}

div#selectPartModal .modal-dialog {
  height: 70vh;
}
.product-count button:disabled {
  background-color: silver;
  border: 1px solid #ccc;
  color: #ccc;
  background: transparent;
}
input[disabled] {
  border-color: #ccc !important;
}
label[disabled] {
  color: #ccc !important;
}
</style>
