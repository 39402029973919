<template>
    

        <div class="modal-content items-wrap">
            <div class="modal-header">
                <span>&nbsp; Approved Parts List</span>
                <span>&nbsp;</span>
                <button @click="closeClicked" type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"><img src="@/assets/images/cross-btn.png"></button>
            </div>
            <div class="modal-body">

                <div class="itemFilter mb-2">
                    <input type="text" v-model="search" placeholder="Enter part name to search..." class="form-control">
                    <!-- <div>
                        <img src="@/assets/images/filter-icon.png">
                    </div> -->
                </div>
                <div class="filterListingBox">
                    <div v-for="(item, index) in filteredList" :key="index" class="checkRow">

                        <label class="check-container"> {{ item.part_name }}
                            <input type="checkbox"
                                :value='{ 
                                    title: item.part_name,
                                    part_price: item.cost_with_markup ?? "", 
                                    service_part_id: item.part_id,
                                    service: item.service }'
                                v-model="selectedItems">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>


                <div class="items-modal-footer">
                    <div class="text-center mt-3" @click="doneBtnClicked"><button class="btn main-button">Done</button>
                    </div>
                </div>

            </div>

        </div>

</template>

<script>

import {
    mapActions,
    mapGetters
} from "vuex";



export default {
    props: ["list", "openModal"],
    components: {
    },

    data() {
        return {
            modal: null,
            search: '',
            selectedItems: [],
            partListArray: []
        }
    },
    computed: {
        ...mapGetters(["apiCallCallback", "partList", "partCategories", "quoteDetail"]),

        filteredList() {
            return this.partListArray.filter((item) => {
                return Object.keys(item)
                    .some(key => ('' + item[key]).toLowerCase().includes(this.search.toLowerCase()))
            })
        }

    },

    watch: {


        partList: function () {
       
        },

        openModal: function () {
                this.partListArray = [];
                let itemsids = [];
                this.list.forEach(item => {
                    itemsids.push(item.service_part_id);
                });
                this.partListArray = this.partList.filter((e) => !itemsids.includes(e.part_id));
        },

    },

    mounted() {
        this.getPartCategoriesData()
        this.getPartListData()
    },

    methods: {

        ...mapActions(["getPartList", "getPartCategories"]),


        getPartListData() {

            this.getPartList({
                uq_id: this.quoteDetail.id
            });

        },

        getPartCategoriesData() {
            this.getPartCategories({
                trade_id: this.quoteDetail.trade_id,
                uq_id: this.quoteDetail.id
            });
        },

        doneBtnClicked() {
            this.$emit("event", this.selectedItems)
            this.closeClicked()
        },

        closeClicked() {
            this.search = '',
            this.selectedItems = []
        },
    }

}
</script>

<style>
.items-wrap .modal-header {
    background: #092E87;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.items-wrap .modal-header .btn-close {
    opacity: 1;
    text-indent: 000;
    background: none;
    margin: 0;
    height: auto;
    padding: 0;
    box-shadow: none;
}

.items-wrap .modal-header .btn-close img {
    height: 22px;
    width: 22px;
}

.itemFilter {
    display: flex;
    align-items: center;
}

.itemFilter input {
    width: 100%;
    height: 45px;
    font-size: 18px;
    margin-right: 15px;
}

.checkRow {
    border-bottom: 1px solid #ccc;
    padding: 15px 0;
}

/* The container */
.check-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #092E87;
    line-height: normal;
    font-weight: normal;
}

/* Hide the browser's default checkbox */
.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #FE5000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* width */
.filterListingBox::-webkit-scrollbar {
    width: 8px;

}

/* Handle */
.filterListingBox::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.filterListingBox {
    max-height: 340px;
    overflow: auto;
    padding-right:10px
}

.main-button {
    background: #FE5000;
    border-radius: 8px;
    box-shadow: 0 4px 5px #999;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 30px;
}
</style>