<template>
  <!-- {{ jobDetail.consumer }} -->
  <div
    v-if="jobDetail.consumer"
    class="consumerDetail text-center border rounded px-3 py-4"
  >
    <h5  v-if ="!jobDetail.depp_schedule.show_consumer_as_resident" class="mb-0 blackText fw-bold">Consumer Details</h5>
    <h5  v-else class="mb-0 blackText fw-bold">Resident Details</h5>

    <img v-if ="!jobDetail.depp_schedule.show_consumer_as_resident"
      :src="jobDetail.consumer.profile_picture"
      class="mx-auto d-table my-3 profilePic"
      alt=""
    />
    <img v-else
      :src="jobDetail.depp_schedule.company_logo"
      class="mx-auto d-table my-3 profilePic"
      alt=""
    />
    <h6 class="mb-0 blueText">{{ jobDetail.consumer.full_name }}</h6>
    <ul v-if="!jobDetail.depp_schedule.show_consumer_as_resident" class="d-flex justify-content-center list-inline my-3">
 
      <li>
        <a :href="'tel:' + jobDetail.consumer.phone" target="_blank">
          <img
            src="../../assets/images/call-icon-1.png"
            alt=""
            class="original-icon"
          />
          <img
            src="../../assets/images/call-hover-icon.png"
            alt=""
            class="hover-icon"
          />
        </a>
      </li>
    </ul>
    <button  v-if="!jobDetail.depp_schedule.show_consumer_as_resident" class="btn buttonOrangeWhite" @click="viewMembers()">
      View Members
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="addItemModal"
      ref="addItemModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="closebutton d-flex justify-content-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideModal"
              />
            </div>
            <div class="modalCntnt">
              <h4 class="mb-0 blueText fw-bold text-center mb-4">
                Family Members
              </h4>
              <div class="memberContact">
                <ul class="list-inline mb-0 mt-3">
                  <li
                    v-for="(item, index) in consumerMembers"
                    :key="index"
                    class="d-flex align-items-center justify-content-between p-2 mb-2 rounded border"
                  >
                    <div class="membersDetail">
                      <h5 class="mb-0 blackText fw-bold">
                        {{ item.first_name }} {{ item.last_name }}
                      </h5>
                      <p class="mb-0 greyText text-start">{{ item.relation }}</p>
                    </div>
                    <a
                      v-if="item.is_allowed"
                      :href="'tel:' + item.phone"
                      target="_blank"
                    >
                      <img
                        src="../../assets/images/call-icon.png"
                        class="callIcon"
                        alt="call-icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Modal } from "bootstrap";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  data() {
    return {
      isToastShow: false,
    };
  },
  computed: {
    ...mapGetters(["jobDetail", "consumerMembers"]),
  },

  watch: {
    consumerMembers: function () {
      if (this.consumerMembers.length <= 0) {
        if (!this.isToastShow) {
          this.isToastShow = true;
        }
      } else {
        this.modal = new Modal(this.$refs.addItemModal);
        this.modal.show();
      }
    },
  },

  components: {},
  // mounted() {
  //   const modalElement = document.getElementById('addItemModal');
  //   modalElement.addEventListener('show.bs.modal', this.addOverflowHidden);
  //   modalElement.addEventListener('hidden.bs.modal', this.removeOverflowHidden);
  // },
  beforeUnmount() {
    const modalElement = document.getElementById('addItemModal');
    modalElement.removeEventListener('show.bs.modal', this.addOverflowHidden);
    modalElement.removeEventListener('hidden.bs.modal', this.removeOverflowHidden);
  },

  methods: {
    ...mapActions(["getConsumerMembers"]),
    addOverflowHidden() {
      document.documentElement.classList.add('overflow-hidden');
    },
    removeOverflowHidden() {
      document.documentElement.classList.remove('overflow-hidden');
    },
    viewMembers() {
      this.getConsumerMembers({
        consumer_id: this.jobDetail.consumer.user_id,
      });
    },
    hideModal() {
      this.modal.hide();
    },
  },
};
</script>

<style>
.consumerDetail .profilePic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
#addItemModal .closebutton {
  filter: none;
  border: 0;
  opacity: 1;
}
#addItemModal .closebutton button {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 0.5rem;
  opacity: 1;
}
</style>
