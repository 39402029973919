<template>
  <div class="container">
    <div class="buttonsGroup text-center mt-3">
   
      <div>
        <button @click="nrgItemsClicked()" class="btn main-button">
          <a v-if = "quoteDetail.price_book_type == 'contractor'" > Select from Price Book </a>
          <a v-else > {{ quoteDetail.partner_info.company_name }} Approved Items </a>
        </button>
      </div>
      <div>
        <button @click="addItemManually()" class="btn main-button mb-2">
          Add Items Manually
        </button>
        <p>(Not approved by {{ quoteDetail.partner_info.company_name }})</p>
      </div>
    </div>

    <div class="quoteBox-wrapp">
      <div class="row">
        <div class="col-md-6 mb-3">
          <div
            v-if="quoteDetail.repair_or_maintenance_fee != null"
            class="qoutationBox-fixed"
          >
            <div>
              <h3>{{ quoteDetail.repair_or_maintenance_fee.name }}</h3>
              <h2>
                <span>$</span>{{ quoteDetail.repair_or_maintenance_fee.price }}
              </h2>
            </div>
          </div>
        </div>

        <div
          v-for="(items, idx) in manualItems"
          :key="items.value"
          class="col-md-6 mb-3"
        >
          <div class="qoutationBox">
            <div @click="deleteItemClicked(items)" class="crossBtn">
              <img src="@/assets/images/cross-btn.png" />
            </div>

            <small
              v-if="
                items.is_covered_by_nrg == 1 &&
                quoteDetail.partner_info.company_name.toLowerCase() == 'vivint'
              "
            >
              Covered by Vivint
            </small>
            <small v-else-if="items.is_covered_by_nrg == 1">
              Covered by NRG
            </small>
            <br v-if="items.is_covered_by_nrg == 1" />

            <label>Title</label>
            <div class="title-field">
              <input
                :id="'title_' + idx"
                type="text"
                v-model="items.title"
                placeholder="Enter Title"
                class="form-control"
              />
            </div>
            <label
              v-if="
                items.service != null &&
                items.service.is_service_fee_applicable == 1
              "
            >
              Part Cost
            </label>
            <label v-else> Fee (Part + Service) </label>
            <div class="quoteField">
              <span>$</span>
              <input
                :id="'price_' + idx"
                type="text"
                v-model="items.part_price"
                @input="validateInput(idx, true)"
                :placeholder="
                  items.service != null &&
                  items.service.is_service_fee_applicable == 1
                    ? 'Enter Part Cost'
                    : 'Enter Fee (Part + Service)'
                "
                :step="0.9"
                @paste="disablePaste"
                @keypress="isNumber($event)"
                class="form-control"
              />
            </div>

            <label
              class="mt-2"
              v-if="
                items.service != null &&
                items.service.is_service_fee_applicable == 1
              "
              >{{
                replaceDollar(
                  items.service.service_fee_title ?? "Service Fee ($)"
                )
              }}</label
            >
            <div
              v-if="
                items.service != null &&
                items.service.is_service_fee_applicable == 1
              "
              class="quoteField"
            >
              <span>$</span>
              <input
                :id="'service_fee_' + idx"
                type="text"
                v-model="items.service.price"
                :placeholder="
                  'Enter ' +
                  replaceDollar(
                    items.service.service_fee_title ?? 'Service Fee ($)'
                  )
                "
                :disabled="items.service.is_service_fee_editable == 0"
                :step="0.9"
                @paste="disablePaste"
                @keypress="isNumber($event)"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade modal-lg"
      ref="modal"
      id="exampleModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <NrgItems
          @event="nrgItemsCallback"
          :list="manualItems"
          :openModal="openModal"
        ></NrgItems>
      </div>
    </div>
    <!-- {{ quoteDetail }} -->
    <div class="text-center mt-5">
      <button @click="finalizeQuoteClicked()" class="btn main-button">
        Finalize Quote
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NrgItems from "@/components/nrg_items/NrgItems.vue";
import { Modal } from "bootstrap";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    NrgItems,
  },

  data() {
    return {
      modal: null,
      manualItems: [],
      postItems: [],
      openModal: false,
    };
  },
  computed: {
    ...mapGetters(["storeQuoteData", "storeQuoteItems", "quoteDetail"]),
  },

  watch: {
    storeQuoteItems: function () {
      const quote = Object.assign({}, this.quoteDetail);
      quote.items = this.manualItems;
      this.updateQuoteItems(quote);
      this.setCommponent("QuoteSummary");
    },
  },

  mounted() {
    this.manualItems.push.apply(this.manualItems, this.quoteDetail.items);
  },

  methods: {
    ...mapActions(["postQuoteData", "setCommponent", "updateQuoteItems"]),

    validateInput(idx, isPartPrice) {
      let regex = /^\d{0,4}(\.\d{0,2})?$/;
      if (isPartPrice) {
        if (!regex.test(this.manualItems[idx].part_price)) {
          this.manualItems[idx].part_price = this.manualItems[
            idx
          ].part_price.slice(0, -1);
        }
      } else {
        if (!regex.test(this.manualItems[idx].service.price)) {
          this.manualItems[idx].service.price = this.manualItems[
            idx
          ].service.price.slice(0, -1);
        }
      }
    },

    replaceDollar(str) {
      return str.replace("($)", "");
    },

    disablePaste(event) {
      event.preventDefault();
    },

    isNumber(evt) {
      evt = evt || window.event;
      const charCode = evt.which || evt.keyCode;
      const inputElement = evt.target;
      const value = inputElement.value;
      const cursorPos = inputElement.selectionStart; // Track cursor position
      // Allow control keys
      if (charCode === 8 || charCode === 46 || charCode === 9) {
        return true;
      }
      // Restrict length to 4 characters before the decimal
      const parts = value.split('.');
      if (parts.length > 1) {
        if (parts[0].length >= 4 && cursorPos <= parts[0].length) {
          evt.preventDefault();
          return false;
        }
        // Allow up to 2 digits after the decimal point
        if (parts[1].length >= 2) {
          evt.preventDefault();
          return false;
        }
      } else if (parts[0].length >= 4 && cursorPos <= parts[0].length) {
        evt.preventDefault();
        return false;
      }
      // Allow only numeric characters and single decimal point
      if ((charCode >= 48 && charCode <= 57) || charCode === 46) {
        if (charCode === 46 && value.indexOf('.') !== -1) {
          evt.preventDefault();
          return false;
        }
        return true;
      }
      evt.preventDefault();
      return false;
    },

    deleteItemClicked(item) {
      if (item.part_price.length > 0 || item.title.length > 0) {
        this.$dialog.show({
          title: "Delete Item",
          text: "Are you sure you want to delete this item?",
          // image: "pop-req-img.png",
          // we are passing callback method for our confirm button
          onConfirm: () => {
            var index = this.manualItems.indexOf(item);
            if (index !== -1) {
              this.manualItems.splice(index, 1);
            }
          },
        });
      } else {
        var index = this.manualItems.indexOf(item);
        if (index !== -1) {
          this.manualItems.splice(index, 1);
        }
      }
    },

    nrgItemsClicked() {
      this.openModal = new Date();
      this.modal = new Modal(this.$refs.modal);
      this.modal.show();
    },

    nrgItemsCallback(data) {
      this.modal.hide();
      data.forEach((value) => {
        let check = this.manualItems.some(
          (vendor) => vendor.service_part_id == value.service_part_id
        );
        if (!check) {
          this.manualItems.push(value);
        }
      });
    },

    addItemManually() {
      if (this.isValid()) {
        const newFirstElement = {
          title: "",
          part_price: "",
          service_part_id: -1,
          service: {
            price: 0,
            service_fee_title: "Service Fee ($)",
            is_service_fee_applicable: 0,
            is_service_fee_editable: 0,
          },
        };
        this.manualItems = [newFirstElement].concat(this.manualItems);
      }
    },

    isValid() {
      let res = true;
      this.postItems = [];
      for (let i = 0; i < this.manualItems.length; i++) {
        let eachItem = this.manualItems[i];
        if (this.isEmpty(eachItem.title)) {
          toast.error("Title field is required.");
          res = false;
          return;
        } else if (this.isEmpty(eachItem.part_price)) {
          let str =
            eachItem.service != null &&
            eachItem.service.is_service_fee_applicable == 1
              ? "Part Cost"
              : "Fee (Part + Service)";
          toast.error(str + " field is required.");
          res = false;
          return;
        } else if (
          eachItem.service.is_service_fee_applicable == 1 &&
          this.isEmpty(eachItem.service.price)
        ) {
          toast.error("Service fee field is required.");
          res = false;
          return;
        }
        this.postItems.push(this.getFomattedObject(eachItem));
      }
      return res;
    },

    getFomattedObject(data) {
      let obj = {
        service_part_id: data.service_part_id ?? -1,
        service_fee: data.service != null ? data.service.price : 0,
        part_price: data.part_price ?? 0,
        title: data.title ?? "",
      };
      return obj;
    },

    finalizeQuoteClicked() {
      if (this.isValid()) {
        this.postQuoteData({
          manual_quote_id: this.quoteDetail.id,
          items: JSON.stringify(this.postItems),
        });
      }
    },

    isEmpty(value) {
      return (
        value == null ||
        (value + "").trim().length === 0 ||
        (value.constructor === Object && Object.keys(value).length === 0)
      );
    },
  },
};
</script>

<style>
.buttonsGroup {
  display: flex;
  justify-content: center;
}

.buttonsGroup .main-button {
  background: #fe5000;
  border-radius: 8px;
  box-shadow: 0 4px 5px #999;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
  margin: 0 20px;
}

.buttonsGroup .main-button:hover {
  background-color: #092e87;
  color: #fff;
}

.quoteBox-wrapp {
  padding: 15px;
  border: 2px solid #999;
  border-radius: 10px;
}

.qoutationBox-fixed {
  padding: 10px;
  border-radius: 8px;
  background-color: #d9d9d9;
  box-shadow: 0 3px 5px #0000004f;
  height: 100%;
  display: flex;
  align-items: center;
}

.qoutationBox {
  margin-bottom: 20px;
  height: 100%;
}

.qoutationBox-fixed h3,
.qoutationBox h3 {
  font-size: 20px;
  color: #092e87;
  font-weight: normal;
  margin-bottom: 15px;
}

.qoutationBox h3 {
  margin-bottom: 23px;
}

.qoutationBox small {
  margin-left: 18px;
  font-size: 12px;
  color: #092e87;
  font-weight: 500;
}

.qoutationBox label {
  margin-left: 18px;
  font-size: 14px;
  color: #fe5000;
  font-weight: 500;
}

.qoutationBox-fixed h2 {
  font-size: 55px;
  color: #fe5000;
  font-weight: 500;
  display: block;
}

.qoutationBox-fixed h2 span {
  font-size: 18px;
  position: relative;
  top: -15px;
}

.qoutationBox {
  padding: 10px;
  border-radius: 8px;
  background-color: #d9d9d9;
  box-shadow: 0 3px 5px #0000004f;
  padding-right: 25px;
  position: relative;
}

.quoteField {
  display: flex;
}

.quoteField span {
  margin-right: 4px;
  color: #fe5000;
  font-size: 18px;
}

.quoteField .form-control {
  height: 40px;
  font-size: 22px;
}

.title-field .form-control {
  margin-left: 15px;
  width: calc(100% - 15px);
  margin-bottom: 5px;
}

.crossBtn {
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: 99;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .buttonsGroup .main-button {
    font-size: 14px;
    padding: 10px 11px;
    margin: 0 6px;
  }

  .buttonsGroup .main-button {
    margin-bottom: 10px;
  }
}
</style>
