<template>
  <div class="container">

  </div>
</template>


<script>
import {
  mapActions,
  mapGetters
} from "vuex";



import browserSignature from 'browser-signature';


// eslint-disable-next-line no-unused-vars
const signature = browserSignature();

export default {
  components: {},

  setup() {

  },

  data() {
    return {
      nonce: this.$route.query.nonce ?? ''
    };
  },
  computed: {
    ...mapGetters(["isSSOLogin", "user"])
  },

  watch: {
    user: function () {

      if (this.user != 'undefined') {
        console.log(this.user)
        console.log("user", this.user.appointment_id)
        localStorage.setItem('user', JSON.stringify(this.user))
        this.$router.push({
          path: `/select/unit/${this.$route.query.booking_id}`,
          query : {is_from_mobile : Number(this.$route.query.is_from_mobile) === 1 ? 1 : 0
          }
        });
      }

    },
  },

  mounted() {

    this.onSSOLogin()

  },

  methods: {
    ...mapActions(["setSSOLogin"]),
    onSSOLogin() {
      console.log("On SSO Login")
      this.setSSOLogin({
        nonce: this.nonce,
        from_device_id: this.$route.query.from_device_id,
        device_id: signature,
        device_type: "web",
        device_token: "{{$guid}}",
        timestamp: (new Date()).getTime()
      });
    },
  },
};
</script>
